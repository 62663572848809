import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("#root");

const Pcard = ({ cardData }) => {
  const { imgURL1, imgURL2, bgColor, _id } = cardData;
  const [imageSrc, setImageSrc] = useState(imgURL1);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const [quotData, setQuotData] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    machine: cardData.title,
  });
  const initialState = {
    name: "",
    phone: "",
    email: "",
    company: "",
    machine: cardData.title,
  };
  const Ojas = () => {
    navigate(`/single/${cardData._id}`, {
      state: {
        newid: _id,
      },
    });

    localStorage.setItem("prdid", _id);
  };
  const handleMouseEnter = () => {
    setImageSrc(imgURL2);
    setIsHovered(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const rep = await fetch("/api/saveQuot", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quotData),
    });

    setQuotData(initialState);
    closeModal();
  };

  const handleMouseLeave = () => {
    setImageSrc(imgURL1);
    setIsHovered(false);
  };

  const bgClass = `bg-pgreen`;

  const openModal = () => {
    setIsOpen(true);
    setQuotData({ ...quotData, machine: cardData.title });
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const onlyNumberKey = (event) => {
    const key = event.key;
    const isNumber = /^[0-9]$/.test(key);
    const isAllowedKey =
      isNumber || key === "Backspace" || key.includes("Arrow");
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };
  const renderModalContent = () => {
    if (isMobile) {
      return (
        <div className="bg-copper">
          <div className=" flex justify-center  h-screen w-screen mr-10 bg-grey bg-opacity-20 backdrop-blur-sm rounded drop-shadow-lg ">
            <div className="mr-20 mt-40">
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" name"
                name="name"
                value={quotData.name}
                onChange={handleChange}
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Phone Number"
                name="phone"
                value={quotData.phone}
                maxLength={10}
                onChange={handleChange}
                // value={formData.phone}
                // onChange={handleChange}
                onKeyDown={onlyNumberKey}
                required
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-dgrey focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Email"
                name="email"
                value={quotData.email}
                onChange={handleChange}
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Company Name"
                name="company"
                value={quotData.company}
                onChange={handleChange}
              />

              <br></br>
              <button
                className="bg-bblue py-2 px-4 hover:bg-bhblue rounded-full text-white text-sm ml-10 mt-12"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-2 bg-grey bg-opacity-30">
          <div className="col-span-1 w-full ml-8 mt-20 ">
            <form className="bg-grey bg-opacity-30 text-center rounded py-8 px-5 shadow max-w-xs">
              <p className="font-semibold font-Poppins text-lg">
                Enter Details
              </p>
              <input
                type="text"
                className="mb-4 w-full bg-grey bg-opacity-40 focus:outline-none py-2 px-2 text-white placeholder-blackrgba"
                placeholder="  name"
                name="name"
                value={quotData.name}
                onChange={handleChange}
              />
              <input
                type="text"
                className="mb-4 w-full bg-grey bg-opacity-40 focus:outline-none py-2 px-2 text-white placeholder-blackrgba"
                placeholder="  Phone Number"
                name="phone"
                value={quotData.phone}
                maxLength={10}
                onChange={handleChange}
                // value={formData.phone}
                // onChange={handleChange}
                onKeyDown={onlyNumberKey}
                required
              />

              <input
                type="text"
                className="mb-4 w-full bg-grey bg-opacity-40 focus:outline-none py-2 px-2 text-white placeholder-blackrgba"
                placeholder="  Email"
                name="email"
                value={quotData.email}
                onChange={handleChange}
              />
              <input
                type="text"
                className="mb-4 w-full bg-grey bg-opacity-40 focus:outline-none py-2 px-2 text-white placeholder-blackrgba"
                placeholder="  company Name"
                name="company"
                value={quotData.company}
                onChange={handleChange}
              />
              <button
                className="bg-bblue py-3 px-5 hover:bg-bhblue rounded-full text-white text-xs mt-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-span-1 text-white flex items-center justify-center text-3xl font-bold w-full bg-[url('https://images.unsplash.com/photo-1584774354932-62ceb99e6053?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80')] h-500">
            Coptech Engineering
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="pl-20 pr-20">
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="bg-pgreen grid w-200 mt-20 h-250">
            <div className="grid-area relative" onClick={Ojas}>
              <img
                className={`image-container transition-all duration-300 ease-in-out ${
                  isHovered
                    ? "h-200 mt-10 shadow-lg "
                    : "h-300  mt-10  shadow-lg"
                }`}
                src={imageSrc}
                alt="Image"
              ></img>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center text-center pl-20 pr-20">
            <p
              className={`image-container ${
                isHovered
                  ? "font-Poppins font-bold text-lg mt-5"
                  : "font-Poppins font-bold text-lg mt-24"
              }`}
            >
              {cardData.title}
            </p>
            <p
              className={`image-container ${
                isHovered
                  ? "font-Poppins text-xs mt-1 font-semibold"
                  : "font-Poppins text-xs mt-2 font-semibold"
              }`}
            >
              {cardData.price}
            </p>
            {isHovered && (
              <div className="flex justify-center items-center">
                <button
                  className="bg-bblue py-3 px-5 hover:bg-bhblue rounded-full text-white text-xs mt-3 whitespace-nowrap"
                  onClick={openModal}
                >
                  Get Quotation
                </button>
              </div>
            )}
            {isHovered && (
              <p className="text-bblue mt-1 text-sm underline font-semibold cursor-pointer whitespace-nowrap">
                <Link className="font-semibold" to="/ourproducts">
                  More Machines
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="h-500 w-750 bg-white ml-25 z-20 mt-100"
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default Pcard;
