import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
// import GoogleMapReact from "google-map-react";
import logo from "./BlackSparrow4.webp";
import { Link } from "react-router-dom";
import GoogleMap from "./Map";

const FooterMob = () => {
  const customStyles = {
    background:
      "linear-gradient(137deg, rgba(0,0,0,1) 0%, rgba(1,1,54,1) 100%)",
  };
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  return (
    <div>
      <div className="   " style={customStyles}>
        <div className="grid grid-cols-2  border-b-2  border-bgcareer pt-5 mr-10 ml-10 pb-4 mb-3">
          <div className="col-span-1  w-full">
            <p className="text-bgcareer text-xs/5 font-Poppins text-left font-bold">
              More
            </p>
            <ul className="mt-3">
              <li className="text-bgcareer  text-xs/5 font-Poppins text-left">
                <Link className="text-white" to="/careersm">
                  Careers
                </Link>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <Link className="text-white" to="/gallerym">
                  Gallery
                </Link>
              </li>

              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a
                  className="text-white"
                  href="mailto:admin@rollingmillscoptech.com"
                >
                  Email us
                </a>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <Link className="text-white" to="/aboutm">
                  About Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-1  w-full">
            <h2 className="text-bgcareer text-xs/5 font-Poppins text-left font-bold">
              Our Products
            </h2>
            <ul className="mt-3">

              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a href="/productm" className="text-bgcareer" >
                  Cold Rolling Mills
                </a>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a href="/produ ctm" className="text-bgcareer" >
                  Rolling Machine
                </a>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a href="/productm" className="text-bgcareer" >
                  Hot Rolling Mills
                </a>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a href="/productm" className="text-bgcareer" >
                  Straightening Machine
                </a>
              </li>
              <li className="text-bgcareer text-xs/5 font-Poppins text-left">
                <a href="/productm" className="text-bgcareer" >
                  Slitting Machine  {" "}
                </a>
                <br></br>
                <a href="/productm" >
                  More ....
                </a>

              </li>

              <li className="text-left">

                <button className="bg-bgcareer text-xs/5 text-black rounded-md px-3 py-0.5 mt-4 font-semibold">
                  <Link to="/productm">View all</Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-2 ">
          <div className="col-span-1  w-full h-72 -mt-2 overflow-y-hidden">
            <div className="w-full h-full overflow-y-hidden ">
              <GoogleMap />
              {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              ></GoogleMapReact> */}
            </div>
          </div>
          <div className="col-span-1  w-full pb-4">
            <div className="pr-5">
              <h3 className="text-white text-xs/5 font-Poppins font-extrabold text-left ml-5 mt-5  ">
                REACH US
              </h3>
              <div className="mt-3 ml-5">
                <h4 className="text-white text-xs/5 font-Poppins text-left font-bold mb-2">
                  S. S. Vaidya (Managing Director)
                </h4>
                <p className="text-white text-xs/5 font-Poppins text-left">
                  91-A, Industrial Area No. 1,

                </p>
                <p className="text-white text-xs/5 text-left ">
                  Dewas - 455001 ,

                </p>
                <p className="text-white text-xs/5 text-left">
                  Madhya Pradesh , India

                </p>
              </div>
            </div>
            {/* <p className="text-white text-xs/5 font-extrabold text-left ml-5 mt-5">
              Socials
            </p> */}
            {/* <div className="flex  w-full  pb-5 ml-5 mt-2">
              <FaInstagramSquare
                className="h-5 w-5 mx-2 "
                style={{ color: "#ffffff" }}
              />
              {/* <FacebookRoundedIcon className="h-5 w-5 mx-2 " /> */}
            {/* <FaLinkedin
                className="h-5 w-5 mx-2 "
                style={{ color: "#ffffff" }}
              />
              <FaTwitterSquare
                className="h-5 w-5 mx-2"
                style={{ color: "#ffffff" }}
              />
            </div>  */}
          </div>
        </div>
      </div>
      <div className=" bg-dgrey pb-20">
        <div className="pl-5 pr-5 pt-5 mr-5 ml-5 pb-4 ">
          <p className="text-white text-xs/6 font-Poppins text-left">
            Contact Us
          </p>
          <div>
            <div className="flex mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="w-4 h-4  mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>

              <a href="tel:+917771874626" className="text-white text-xs/10 font-Poppins text-left -mt-3">
                +91 777 187 4626
              </a>

            </div>
            <div className="flex -mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                class="w-4 h-4 mr-2"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              <a
                href="mailto:admin@rollingmillscoptech.com"
                className="text-white text-xs"
              >
                admin@rollingmillscoptech.com
              </a>
            </div>
          </div>
        </div>
        <div className="mr-10 ml-10 border-b-2  border-bgcareer pb-5">
          <p className="text-white text-xs/5 font-Poppins text-left ">
            © 2023 Coptech Engineering Private Limited. All Rights Reserved
            (Terms of Use).
          </p>
        </div>
        <div className="justify-center text-center flex p-4">
          {/* <img
            classname=" mr-0 ml-2"
            height="40px"
            width="40px"
            src={logo}
          ></img> */}

          <a
            className="text-white bottom-0 font-bold right-0 mt-3"
            href="mailto:blacksparrowdevs@gmail.com"
          >
            Crafted by BlackSparrow
            {/* <p className="text-2xs mt-1">We Develop .</p> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterMob;
