import React from 'react';

const Popup = ({ onClose }) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#111827] bg-opacity-50">
            <div className="bg-white rounded-lg p-6 font-Poppins">
                <h2 className="text-lg font-bold mb-4 text-center">Enquiry Submitted!</h2>
                <p className="text-[#151d2f]">Thank you for showing your interest!!</p>
                <p className="text-[#111827] text-center">We will get to you ASAP</p>
                <p className="text-[#111827] text-center">Keep checking your E-Mail</p>
                <div className="flex justify-center mt-2">
                    <button
                        className="mt-2 bg-[#003A72] hover:bg-[#333333] text-white py-2 px-4 rounded flex items-center justify-center font-Poppins"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
