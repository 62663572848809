import React, { useEffect, useState, useRef } from "react";
import m from "./coptech products/tandam rolling mill (2).jpg";
import { Location } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "rsuite/esm/Loader";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../Navbar/NavbarMobile";
import Navbar from "../Navbar/Navbar";
const SinglePr = ({ details }) => {
  const navigate = useNavigate();
  const saveid = localStorage.getItem("prdid");
  const location = useLocation();
  const { id } = useParams();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [mydata, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [mainImage, setMainImage] = useState("");

  Modal.setAppElement("#root");

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const [mac, setmac] = useState("");
  const [quotData, setQuotData] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    machine: "",
  });
  const initialState = {
    name: "",
    phone: "",
    email: "",
    company: "",
    machine: "",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rep = await fetch("/api/saveQuot", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quotData),
    });

    setQuotData(initialState);
    closeModal();
  };

  const onlyNumberKey = (event) => {
    const key = event.key;
    const isNumber = /^[0-9]$/.test(key);
    const isAllowedKey =
      isNumber || key === "Backspace" || key.includes("Arrow");
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };
  const fetcher = async () => {
    const products = await fetch(`/api/getPrd?id=${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await products.json();
    setdata(data);
    setMainImage(data[0].imgURL1);
    setmac(data[0].title);
  };
  const changeMainImage = (image) => {
    setMainImage(image);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    fetcher();
    setQuotData({ ...quotData });
  }, []);

  const openModal = () => {
    setIsOpen(true);

    setQuotData({ ...quotData, machine: mac });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModalContent = () => {
    if (isMobile) {
      return (
        <div className="bg-copper">
          <div className=" flex justify-center  h-screen w-screen mr-10 bg-grey bg-opacity-20 backdrop-blur-sm rounded drop-shadow-lg ">
            <div className="mr-20 mt-40">
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" name"
                name="name"
                value={quotData.name}
                onChange={handleChange}
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Phone Number"
                name="phone"
                value={quotData.phone}
                maxLength={10}
                onChange={handleChange}
                // value={formData.phone}
                // onChange={handleChange}
                onKeyDown={onlyNumberKey}
                required
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-dgrey focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Email"
                name="email"
                value={quotData.email}
                onChange={handleChange}
              />
              <br></br>
              <input
                type="text"
                className="mb-4 w-150 border-b border-black focus:border-black focus:outline-none py-2 px-2 text-bgcareer bg-grey bg-opacity-30 placeholder-bgcareer"
                placeholder=" Company Name"
                name="company"
                value={quotData.company}
                onChange={handleChange}
              />

              <br></br>
              <button
                className="bg-bblue py-2 px-4 hover:bg-bhblue rounded-full text-white text-sm ml-10 mt-12"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-2 bg-grey bg-opacity-30">
          <div className="col-span-1 w-full ml-8 mt-20 ">
            <form className="bg-grey bg-opacity-30 text-center rounded py-8 px-5 shadow max-w-xs">
              <p className="font-semibold font-Poppins text-lg">
                Enter Details
              </p>
              <input
                type="text"
                className="mb-4 w-full border-b border-black focus:border-black bg-grey bg-opacity-40 focus:outline-none py-2 px-4n  placeholder-blackrgba"
                placeholder="  name"
                name="name"
                value={quotData.name}
                onChange={handleChange}
              />
              <input
                type="text"
                className="mb-4 w-full border-b border-lime-50 focus:border-black  bg-grey bg-opacity-40 focus:outline-none py-2 px-  placeholder-blackrgba"
                placeholder="  Phone Number"
                name="phone"
                value={quotData.phone}
                maxLength={10}
                onChange={handleChange}
                // value={formData.phone}
                // onChange={handleChange}
                onKeyDown={onlyNumberKey}
                required
              />

              <input
                type="text"
                className="mb-4 w-full border-b border-black focus:border-black focus:outline-none py-2 px-4n bg-grey bg-opacity-40  placeholder-blackrgba"
                placeholder="  Email"
                name="email"
                value={quotData.email}
                onChange={handleChange}
              />
              <input
                type="text"
                className="mb-4 w-full border-b border-black focus:border-black focus:outline-none py-2 px-4n bg-grey bg-opacity-40 placeholder-blackrgba"
                placeholder="  company Name"
                name="company"
                value={quotData.company}
                onChange={handleChange}
              />
              <button
                className="bg-bblue py-3 px-5 hover:bg-bhblue rounded-full text-white text-xs mt-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-span-1 text-white flex items-center justify-center text-3xl font-bold w-full bg-[url('https://images.unsplash.com/photo-1584774354932-62ceb99e6053?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80')] h-500">
            Coptech Engineering
          </div>
        </div>
      );
    }
  };

  return (
    <div className="overflow-x-hidden h-screen bg-bgcareer">
      {isDesktopOrLaptop && (
        <>
          <Navbar />

          <div className="bg-bgcareer overflow-hidden ">
            <div className="grid grid-cols-10 pb-150  overflow-hidden mt-150">
              <div className="col-span-4 col-start-1 w-full ">
                <div className="flex justify-end items-center align-middle ml-48">
                  <div>
                    {mydata.map((prop) => (
                      <div>
                        <img
                          className="h-250 mx-auto mt-501 bg-imgbg p-4"
                          src={mainImage}
                          alt="Tandam Rolling Mill"
                        />
                        <div className="flex gap-4 w-100 ">
                          <img
                            src={prop.imgURL1}
                            alt="Thumbnail 1"
                            className="w-12 h-12 cursor-pointer inline-block"
                            onClick={() => changeMainImage(prop.imgURL1)}
                          />
                          <img
                            src={prop.imgURL2}
                            alt="Thumbnail 2"
                            className="w-12 h-12 cursor-pointer inline-block"
                            onClick={() => changeMainImage(prop.imgURL2)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-end items-center align-middle ml-48">
                  <p className="text-left font-Poppins text-grey text-base font-normal">
                    *Price will vary from 50 lacs to 2 Crores as per technical{" "}
                    specification & customization
                  </p>
                  {/* <p>{location.state.newid}</p> */}
                </div>
              </div>

              <div className="col-span-6 px-40 col-start-5 w-full  text-left ">
                <div>
                  <p className="mr-200 text-base font-Poppins font-normal">
                    {/* Hot rolling is a metalworking process in which metal is heated
                above the re-crystallization temperature to plastically deform
                it in the working or rolling operation. This process is used to
                create shapes with the desired geometrical dimensions and
                material properties while maintaining the same volume of metal.
                The hot metal is passed between two rolls to flatten it,
                lengthen it, reduce the cross-sectional area and obtain a
                uniform thickness. */}
                  </p>

                  <div>
                    <div className="flex flex-wrap">
                      {mydata.map((detail) => (
                        <div key={detail._id} className="w-full p-2 ">
                          <div className="w-full">
                            <h1 className="font-medium text-4xl mb-5 font-Poppins mt-501 text-bblue1">
                              {detail.title}
                            </h1>
                            <h1 className=""></h1>
                            <p className="font-Poppins">{detail.desc}</p>
                          </div>
                          <div className="flex flex-wrap ">
                            {detail.props.map((prop) => (
                              // <div
                              //   key={prop.id}
                              //   class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                              // >
                              //   <p class="flex item">
                              //     {prop.key}: {prop.value}
                              //   </p>
                              // </div>
                              <div
                                key={prop.id}
                                className="w-full  sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
                              >
                                <div className="flex item">
                                  <div>
                                    <p className="font-semibold">{prop.key}</p>
                                    <br></br>

                                    <p className="-mt-4">{prop.value}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="flex ">
                            <div className=" font-Poppins leading-9 px-5 font-medium border-2 border-pgreen inline-block  justify-center rounded-full mt-5 ">
                              <p className="font-Poppins">{detail.price}</p>
                            </div>
                            <div className=" font-Poppins leading-9 px-5 mx-10 font-medium border-2 border-pgreen inline-block justify-center rounded-full mt-5 ">
                              <button
                                className="font-Poppins"
                                onClick={openModal}
                              >
                                Get Latest Price
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="overflow-y-scroll overflow-x-hidden">
        {isTabletOrMobile && (
          <>
            <div>
              <p className="bg-bblue w-screen font-semibold text-lg text-white flex justify-center py-2">
                Coptech Rolling Mills
              </p>
            </div>
            <NavbarMobile />

            <div className="bg-bgcareer overflow-hidden ">
              <div className="flex justify-center items-center align-middle ">
                <div>
                  {mydata.map((prop) => (
                    <div>
                      <div className="border-bgrey rounded-md border-2 p-2 mt-6 mx-4">
                        <img
                          className="h-250 mx-auto mt-501 bg-imgbg p-4  "
                          src={mainImage}
                          alt="Tandam Rolling Mill"
                        />
                      </div>
                      <div className="flex gap-4 w-100 mt-5 ">
                        <div
                          className={`border-b ${
                            selectedImage === prop.imgURL1
                              ? "border-bblue"
                              : "border-bgrey"
                          } p-2 rounded-md border-2 ml-4`}
                          onClick={() => setSelectedImage(prop.imgURL1)}
                          onBlur={() => setSelectedImage(null)}
                          tabIndex={0}
                        >
                          <img
                            src={prop.imgURL1}
                            alt="Thumbnail 1"
                            className="w-12 h-12 cursor-pointer inline-block"
                            onClick={() => changeMainImage(prop.imgURL1)}
                          />
                          <p className="border-t-2 border-bgrey text-tblue w-full mt-2">
                            Image1
                          </p>
                        </div>
                        <div
                          className={`border-b ${
                            selectedImage === prop.imgURL2
                              ? "border-bblue"
                              : "border-bgrey"
                          } p-2 rounded-md border-2`}
                          onClick={() => setSelectedImage(prop.imgURL2)}
                          onBlur={() => setSelectedImage(null)}
                          tabIndex={0}
                        >
                          <img
                            src={prop.imgURL2}
                            alt="Thumbnail 2"
                            className="w-12 h-12 cursor-pointer inline-block"
                            onClick={() => changeMainImage(prop.imgURL2)}
                          />
                          <p className="border-t-2 text-tblue border-bgrey w-full mt-2">
                            Image2
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-center ">
                {/* <p className="text-left font-Poppins text-black opacity-50 text-base font-normal ml-5">
                  *Price will vary from 50 lacs to 2 Crores as per technical{" "}
                  specification & customization
                </p> */}
                {/* <p>{location.state.newid}</p> */}
              </div>

              <div>
                <p className="mr-200 text-base font-Poppins font-normal">
                  {/* Hot rolling is a metalworking process in which metal is heated
                above the re-crystallization temperature to plastically deform
                it in the working or rolling operation. This process is used to
                create shapes with the desired geometrical dimensions and
                material properties while maintaining the same volume of metal.
                The hot metal is passed between two rolls to flatten it,
                lengthen it, reduce the cross-sectional area and obtain a
                uniform thickness. */}
                </p>

                <div>
                  <div className="flex flex-wrap px-5">
                    {mydata.map((detail) => (
                      <div key={detail._id} className="w-full p-2 ">
                        <div className="w-full border-b-2 border-bgrey pb-4">
                          <h1 className="font-medium text-xl mb-5 font-Poppins mt-501 text-bblue">
                            {detail.title}
                          </h1>
                          <h1 className=""></h1>
                          <p className="font-Poppins text-blackrgba">
                            {detail.desc}
                          </p>
                        </div>
                        <div className="flex flex-wrap border-bgrey border-2 rounded-md p-4 mt-4">
                          {detail.props.map((prop) => (
                            // <div
                            //   key={prop.id}
                            //   class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                            // >
                            //   <p class="flex item">
                            //     {prop.key}: {prop.value}
                            //   </p>
                            // </div>
                            <div key={prop.id} className=" w-1/2 ">
                              <div className="flex item my-2">
                                <div>
                                  <p className="font-semibold">{prop.key}</p>
                                  <br></br>

                                  <p className="-mt-4 text-bblue">
                                    {prop.value}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className=" mb-24">
                          <div className=" font-Poppins leading-9 px-5 text-white bg-grey font-medium border-2 border-pgreen inline-block  justify-center rounded-full mt-5 ">
                            <p className="font-Poppins">{detail.price}</p>
                          </div>
                          <br></br>
                          <div className=" font-Poppins leading-9 px-5  font-medium border-2 border-pgreen inline-block justify-center rounded-full mt-5 ">
                            <button
                              className="font-Poppins"
                              onClick={openModal}
                            >
                              Get Latest Price
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="h-500 w-750 bg-white ml-25 z-20 mt-100"
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default SinglePr;
