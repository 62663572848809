import React from "react";
import Company from "./Company";
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar/Navbar";
import NavbarMobile from "../Navbar/NavbarMobile";
import MobileTop from "../Navbar/MobileTop";
const Client = () => {
  const clients = [
    {
      logo: "/Assets/apar.png",
      name: "APAR",
    },
    {
      logo: "/Assets/bedmutha.png",
      name: "BEDMUTHA INDUSTRIES ",
    },
    {
      logo: "/Assets/bhagyanagar-logo.jpg",
      name: "BHAGYANAGAR INDIA LTD",
    },
    {
      logo: "/Assets/coprrod.png",
      name: "COPPRROD",
    },
    {
      logo: "/Assets/LT.png",
      name: "LARSEN & TOURBO",
    },
    {
      logo: "/Assets/nuhas.png",
      name: "NUHAS",
    },
    {
      logo: "/Assets/pprolling.png",
      name: "PP ROLLING MILLS ",
    },
    {
      logo: "/Assets/emjay.png",
      name: "EMJAY",
    },
    {
      logo: "/Assets/genus.png",
      name: "GENUS ELECTROMECH",
    },
    {
      logo: "/Assets/pahwa.webp",
      name: "PAHWA METAL TECH",
    },
    {
      logo: "/Assets/vishal.jpg",
      name: "VISHAL METALLICS",
    },
    {
      logo: "/Assets/tamra.png",
      name: "TAMRA DZYN",
    },
  ];
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <>
      <div className="bg-slate">
        <div>{isTabletOrMobile && <MobileTop />}</div>
        {isDesktopOrLaptop && <Navbar />}
        <div className="h-10 "></div>
        <div className="min-h-screen mx-5 md:mx-40  mt-24 ">
          <div className="mt-24  text-center bg-[#003A72]   mb-10">
            <h1 className="text-xl md:text-4xl text-white font-semibold">Our Customers</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-2 justify-center mx-10 mb-0 md:mx-auto">
            {clients.map((cli, index) => (
              <Company key={index} logo={cli.logo} name={cli.name} />
            ))}
          </div>
          <div>{isTabletOrMobile && <NavbarMobile />}</div>
        </div>
      </div>
    </>
  );
};

export default Client;
