import React from "react";
import Vacantm from "./Vacantm";
// import { useMediaQuery } from "react-responsive";
// import Navbar from "../Navbar/Navbar";
import NavbarMobile from "../Navbar/NavbarMobile";
import MobileTop from "../Navbar/MobileTop";

const CareesMob = () => {
  return (
    <>
      <MobileTop />
      <div className="mt-24">
        <div className="overflow-x-hidden">
          <div className="h-screen  bg-bgCareerM bg-cover overflow-x-hidden p-4">
            <p className="text-sm font-Poppins font-medium pt-10 overflow-x-hidden">
              Working with Coptech
            </p>
            <h1 className="text-4xl font-Poppins font-medium">
              Careers with us
            </h1>
            <div className="h-[calc(100vh-180px)] overflow-y-scroll overflow-x-hidden mt-5">
              <Vacantm />
            </div>
          </div>
        </div>
      </div>
      <NavbarMobile />
    </>
  );
};

export default CareesMob;
