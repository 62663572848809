import React from "react";
import logo from "./BlackSparrow4.webp";
import "./Styles.css";
import GoogleMap from "./Map";
// import backgroundImage from "./image/coppernew.webp";
import GoogleMapReact from "google-map-react";

import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer2 = () => {
  const customStyles = {
    background:
      "linear-gradient(137deg, rgba(0,0,0,1) 0%, rgba(1,1,54,1) 100%)",
  };
  const imgl = "BlackSparrow.png";
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const customHeightStyle = {
    height: "230px", // You can adjust the height value as per your requirement
  };
  return (
    // <div
    //   className="flex flex-col md:flex-row items-center bg-cover p-5 md:p-20 "
    //   style={{
    //     backgroundImage: `url(${backgroundImage})`,
    //   }}
    // >
    <div className="grid grid-cols-11 pb-5 " style={customStyles}>
      <div className="col-span-4  w-full bg-#313131">
        <div className="flex ml-5 mt-6">
          <div className="">
            <h2 className="text-white text-xl font-Poppins text-left font-bold">
              Our Company
            </h2>
            <ul className="ml-6">
              <li className="text-white  text-lg font-Poppins text-left">
                About Us
              </li>
              {/* <li className="text-white  text-lg font-Poppins text-left">
                  Corporate Video
                </li>
                <li className="text-white text-lg font-Poppins text-left">
                  Sitemap
                </li> */}
              <li className="text-white  text-lg font-Poppins text-left">
                Contact Us
              </li>
            </ul>
          </div>
          <div className="ml-6">
            <h2 className="text-white text-xl font-Poppins text-left font-bold">
              Our Products
            </h2>
            <ul className="ml-7">
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Rolling Machine
                </a>
              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Hot Rolling Mills
                </a>
              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Straightening Machine
                </a>

              </li>
              <li className="text-white text-lg font-Poppins text-left">

              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Cold Rolling Mills
                </a>
              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Mild Steel Pay Off Machine
                </a>
              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Coiler Machine
                </a>
              </li>
              <li className="text-white text-lg font-Poppins text-left">
                <a href="/ourproducts" className="text-white text-lg font-Poppins text-left hover:no-underline hover:text-slate" >
                  Foil Rewinding Machine
                </a>
              </li>
              <li className="text-left">
                <button className="bg-white text-lg text-black rounded-md px-3 py-0.5 mt-4 font-semibold">
                  <Link to="/ourproducts">View all</Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-white text-xs/5 font-Poppins text-left mt-16 ml-5">
          © 2023 Coptech Engineering Private Limited. All Rights Reserved (Terms
          of Use).
        </p>
      </div>
      <div className="col-span-4  w-full">
        <div
          className="w-450 h-96 overflow-y-hidden pt-5 pl-5"
          style={customHeightStyle}
        >
          <GoogleMap className="h-96" />
          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          ></GoogleMapReact> */}
        </div>
        <div className=" ml-5 mt-11">
          <div className="flex mb-2">
            <p className="text-white text-xl font-Poppins text-left">
              Contact Us
            </p>
            {/* <p className="text-white text-xs/6 font-Poppins pl-48">Share us</p> */}
          </div>
          <div className="flex text-left mb-2">
            {/* <FaPhone style={{ color: "#ffffff", borderRadius: '50%' }} className="mt-3" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              className="w-6 h-6 mt-1 mr-2"
            >
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex text-end">
              <p className="text-white text-xl  font-Poppins ">+91 7771874626</p>
              {/* <div className="flex ml-36 mt-1 ">
                <FaInstagramSquare
                  className="h-5 w-5 mx-2 "
                  style={{ color: "#ffffff" }}
                />
                {/* <FacebookRoundedIcon className="h-5 w-5 mx-2 " /> */}
              {/* <FaLinkedin
                  className="h-5 w-5 mx-2 "
                  style={{ color: "#ffffff" }}
                />
                <FaTwitterSquare
                  className="h-5 w-5 mx-2  "
                  style={{ color: "#ffffff" }}
                />
              </div>  */}
            </div>
          </div>
          <div className="flex text-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              class="w-6 h-6 mr-2"
            >
              <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
            <a
              href="mailto:admin@rollingmillscoptech.com"
              className="text-white text-xl"
            >
              admin@rollingmillscoptech.com
            </a>
          </div>
        </div>
      </div>
      <div className="col-span-3  p-5 w-full place-items-end">
        <div className=" ">
          <h3 className="text-white text-xl font-Poppins font-extrabold text-left  mt-5">
            REACH US
          </h3>
          <div className="mt-3">
            <h4 className="text-white text-lg font-Poppins text-left font-bold">
              S. S. Vaidya (Managing Director)
            </h4>
            <p className="text-white text-lg font-Poppins text-left mt-3">
              91-A, Industrial Area No. 1,
              {/* C/B/3, D Ornate Flat No 601,{" "} */}
            </p>
            <p className="text-white text-lg text-left ">
              {/* Gorani Compound, Sudama Nagar */}
              Dewas - 455001 ,
            </p>
            <p className="text-white text-lg text-left">
              Madhya Pradesh , India
            </p>
          </div>
        </div>
        <button className=" mt-24 bg-white text-lg/8 text-black rounded-md px-3 py-0.5 font-semibold ">
          <Link to="/login">Admin Login</Link>
        </button>

        <div className="flex mt-4 justify-end items-center">
          {/* <img
            className="ml-auto"
            height="40px"
            width="40px"
            src={logo}
            alt="Logo"
          /> */}
          <a
            className="text-white font-bold ml-2 hover:no-underline"
            href="mailto:blacksparrowdevs@gmail.com"
          >
            Crafted by BlackSparrow
          </a>
        </div>
        <div className="flex mt-0 justify-end items-center">
          <p className="ml-32 text-bgrey">We Code .</p>
        </div>
      </div>
    </div>
    // </div>

  );
};

export default Footer2;
