import React from "react";
import logo from "./logosmall.png";
import { Link as Li } from "react-scroll";
import { Link } from "react-router-dom";
import { AiFillPhone } from "react-icons/ai";

const MobileTop = () => {
  return (
    <div className="w-full bg-[#ECECEC] z-10 p-4 fixed top-0">
      <div className="w-full h-24 flex">
        <div className="flex w-2/3 items-center gap-2">
          <Link to="/">
            <img src={logo} className="h-16 w-28" alt="Logo" />
          </Link>
          <h2 className="font-black	 text-[#003A72] text-base align-top">
            Coptech <br /> Engineering
          </h2>
        </div>
        {/* <div className="flex w-2/3 items-center ml-10 ">
          <h2 className="font-black	 text-[#003A72] text-lg align-top">
            Coptech <br /> Engineering
          </h2>
        </div> */}
        <div className="w-1/3 items-center grid grid-cols-1 mr-2 justify-end ">
          {/* <div className="flex text-left "> */}
          {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="24 24 24 24"
              fill="white"
              className="w-6 h-6 mt-1 mr-2"
            ></svg> */}
          {/* <AiFillPhone className="text-sm text-[#003A72] m-1 animate-pulse" /> */}

          {/* <div className="flex text-end"> */}
          {/* <p className="text-xs font-Poppins text-[#003A72] animate-pulse">+91 777 187 4626</p> */}
          {/* </div> */}
          {/* </div> */}
          <div className="flex text-left">
            <a
              href="tel:+917771874626"
              className="flex items-center justify-end"
            >
              <AiFillPhone className="text-sm text-[#003A72] mr-1 duration-500 animate-pulse" />
              <p className="text-xs text-bold font-Poppins text-[#003A72]  duration-500  animate-pulse">
                +91 777 187 4626
              </p>
            </a>
          </div>

          {/* <div className="bg-white h-10 w-px mx-6"></div> */}

          <button className="px-0 py-1 -mt-8 text-white hover:no-underline bg-[#003A72]">
            <Li to="footer" spy={true} smooth={true}>
              <Link
                className="text-white hover:no-underline hover:text-white text-sm"
                to="/"
              >
                Contact Us
              </Link>
            </Li>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileTop;
