import React from "react";

const vacancy = [
  {
    id: 0,
    title: "General Manager",
    desc: "Thank you for your interest in the General Manager position at our company.",
    time: "Full time",
    linkk: "https://forms.gle/YmDFf8GfCPQ7m5Rs8",
  },
  {
    id: 2,
    title: "Machine Operator",
    desc: "Thank you for your interest in the Machine Operator position at our company.",
    time: "Full time",
    linkk: "https://forms.gle/85N779zd9WbBSdn88",
  },
  {
    id: 3,
    title: "Mechanical Engineer",
    desc: "Thank you for your interest in the Mechanical Engineer position at our company.",
    time: "Full time",
    linkk: "https://forms.gle/HU4eYoWh2vZSAGMWA",
  },
  {
    id: 4,
    title: "AutoCAD designer",
    desc: "Thank you for your interest in the autoCAD designer position at our company",
    time: "Full time",
    linkk: "https://forms.gle/ZAyCfNhL54YLc9vF9",
  },
  {
    id: 5,
    title: "Painter",
    desc: "Thank you for your interest in the Painter position at our company",
    time: "Full time",
    linkk: "https://forms.gle/DkCShovNn8nahHBQ9",
  },
];
const handleclk = (linkk) => {
  window.location.href = linkk;
};

const Vacant = () => {
  return vacancy.map((p) => (
    <div className="w-1170 h-32 border-b-2 text-left border-uline pt pb-36">
      <p className="font-Poppins h-6 font-semibold text-base text-left mt-501 ">
        {p.title}
      </p>
      <div className="flex">
        <p className="font-Poppins font-medium text-left  leading-5 mt-4">
          {p.desc}
        </p>
      </div>
      <div className="flex ">
        <p className="font-Poppins font-bold text-sm text-left  w-24  mt-7 ">
          {p.time}
        </p>
        <ul style={{ listStyleType: "none" }}>
          <li key={p.id}>
            <button
              className=" font-bold leading-5  rounded-full border-solid border-2 py-1 mt-5 items-center flex justify-center px-3 -pt-1"
              onClick={() => handleclk(p.linkk)}
              id={p.id}
            >
              Apply
            </button>
          </li>
        </ul>
      </div>
    </div>
  ));
};

export default Vacant;
