import React from "react";
import logo from "./logosmall.png";
import { Link as Li } from "react-scroll";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="w-full bg-[#ECECEC] z-10 h-24 p-6 fixed top-0">
      <div className="w-full h-12 flex justify-between">
        <div className="flex w-8/10 ">

          <div className="flex w-2/10 items-center gap-2">
            <Link to="/" >
              <img src={logo} className="h-28 w-40 p-2" />
            </Link>
            <Link to="/" className="hover:no-underline">
              <h2 className="font-bold text-[#003A72] text-xl ">
                Coptech <br></br> Engineering
              </h2>
            </Link>

          </div>
          <div className="flex w-6/10 items-start justify-start ml-10 ">
            <ul className="flex gap-9 text-[#003A72] m-auto">
              <li>
                <Link
                  className="font-semibold text-lg"
                  to="/"
                  style={{ textDecoration: "none" }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="font-semibold text-lg"
                  to="/aboutus"
                  style={{ textDecoration: "none" }}
                >
                  About
                </Link>
              </li>
              <li>
                {/* <Link
                className="font-semibold text-lg"
                to="/ourproducts"
                style={{ textDecoration: "none" }}
              >
                Our Products
              </Link> */}
                <div class="relative group">
                  {/* <a
                    href="/ourproducts"
                    class="font-semibold text-lg"
                    style={{ textDecoration: "none" }}
                  >
                    Our Products
                  </a> */}

                  <a
                    href="/ourproducts"
                    class="font-semibold text-lg flex items-center"
                    style={{ textDecoration: "none" }}
                  >
                    Our Products
                    <svg
                      class="h-4 ml-2 transform transition-transform duration-200 group-hover:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </a>
                  <ul class="absolute hidden -mt-1 -ml-10  space-y-2 bg-white p-2 shadow-md group-hover:block">
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          COLD ROLLING MILL (REVERSIBLE)				                        </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          HOT ROLLING MILL (REVERSIBLE)                        </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          CLADDING MILL							                         </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          TANDEM ROLLING MILL						                         </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          STRAIGHTENING MACHINE						                        </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          SLITTING LINE							 	                         </a>
                      </div>
                    </li>

                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue-100 rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          SCALPING MACHINE				                        </a>
                      </div>
                    </li>
                    <li class="transition duration-300 ease-in-out transform hover:scale-110">
                      <div class="bg-blue rounded-lg p-2 m-2 hover:border hover:border-black-800 hover:bg-slate">
                        <a href="/ourproducts" class="block px-2 py-1 whitespace-nowrap hover:text-blue-500 hover:no-underline">
                          ALLIED EQUIPMENTS							 						 	                         </a>
                      </div>
                    </li>
                  </ul>
                </div>

              </li>
              <li>
                <Link
                  className="font-semibold text-lg"
                  to="/gallery"
                  style={{ textDecoration: "none" }}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  className="font-semibold text-lg"
                  to="/careers"
                  style={{ textDecoration: "none" }}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  className="font-semibold text-lg"
                  to="/ourclients"
                  style={{ textDecoration: "none" }}
                >
                  Our Customers
                </Link>
              </li>

            </ul>
          </div>
        </div>


        <div className="flex w-2/10 items-center justify-end">
          <div className="flex text-left mr-8">
            {/* <FaPhone style={{ color: "#ffffff", borderRadius: '50%' }} className="mt-3" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#003A72"
              className="w-6 h-6 mt-1 mr-2 text-[#003A72]"
            >
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex text-end">
              <p className=" text-xl  font-Poppins text-[#003A72]">+91 7771874626</p>

            </div>
          </div>
          <button className="px-3 py-1 text-white hover:no-underline bg-[#003A72] ">
            <Li to="footer" spy={true} smooth={true}>
              <Link
                className="text-white hover:no-underline hover:text-white"
                to="/"
              >
                Contact Us
              </Link>
            </Li>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
