import React, { useState, useEffect, useRef } from "react";
import "../../index.css";

const VideoResp = () => {
  const [isHovered, setIsHovered] = React.useState(false);

  const Customgrad = {
    background: "linear-gradient(235deg, #003A72 2.39%, #010101 87.96%)",
    height: "900px",
  };

  const videoRef = useRef(null);

  useEffect(() => {
    // Play the video when the component mounts
    videoRef.current.play();
  }, []);

  return (
    <div class="w-screen " style={Customgrad}>
      <div class="w-auto text text-black text-3xl font-semibold pt-12 pb-12">
        <h1 class="text-white font-semibold text-4xl md:text-5xl lg:text-5xl px-4 font-outfit ml-8 flex">
          Leading Machine Manufacturer In Indore
        </h1>
      </div>
      {/* Video  */}
      <div className=" flex justify-center  items-center ">
        <div className="aspect-auto p-4  py-2 h-6/12   mb-8  ">
          <div className="drop-shadow-2xl filter bg-black ">
            <video
              ref={videoRef}
              class="rounded-sm h-72"
              controls
              autoPlay
              muted
            >
              <source
                src={
                  "https://ik.imagekit.io/coptech/CoptechVideo?updatedAt=1689878168432"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>

      <div class="flex justify-left ml-8 items-center mb-5 text-white font-normal text-lg md:text-xl lg:text-xl md:top-80 lg:top-80 font-outfit  align-text-top top-72 left-16">
        <p>We deal in</p>
      </div>

      <div class="flex-nowrap w-auto justify-center items-center h-auto">
        <div className="flex justify-center items-center px-4">
          <div>
            <div className="flex">
              <button
                class="px-4 m-1 py-2 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top top-330 left-16 md:top-md330"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Rolling Machines
              </button>
              <button
                class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top top-330  left-16 md:top-md330"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Hot Rolling Machines
              </button>
            </div>
            <div className="flex">
              <button
                class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top sm:top-301 sm:left-16 top-302 left-16 md:top-md301"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Straightening Machines
              </button>

              <button
                class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
             align-text-top sm:top-301 sm:left-62 top-303 left-16 md:top-md301"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Sitting Machines
              </button>
            </div>
            <div className="flex">
              <button
                class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top sm:top-302 sm:left-16 top-304 left-16 md:top-md303"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Cold Rolling Machines
              </button>

              <button
                class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
             align-text-top sm:top-302 sm:left-md61 top-305 left-16 md:top-md303"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // onClick={handleClick}
              >
                Foil Rewarding Machines
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Hover Card */}
      {isHovered && (
        <div class="p-3 mt-4 w-80 rounded-md  shadow-xl bg-transparent backdrop-blur-lg absolute m-card -left-md301 border-2 border-white ">
          <div class="max-w-sm">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2 text-white font-outfit"></div>
              <p class="text-slate-202 text-base font-outfit text-white">
                For more info: Go to Our Products Page
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoResp;
