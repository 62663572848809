import React, { useState, useEffect } from "react";
import Pcard from "./Pcard";
import M1 from "./coptech products/2 hi hot rolling mill 3.jpg";
import M2 from "./coptech products/slitting machine (2).jpg";
import M3 from "./coptech products/2 hi cold rolling mill 2.jpg";
import M4 from "./coptech products/tandam rolling mill.jpg";
import { Link } from "react-router-dom";
import Loader from "rsuite/Loader";

const MainProduct = () => {
  const [products, setproducts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getMachines = async () => {
    const products = await fetch("/api/ourProducts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await products.json();
    setproducts(data);
    setLoading(false);
  };

  useEffect(() => {
    getMachines();
  }, [setLoading]);
  const cardDataArray = [
    // {
    //   id:,
    //   title:,
    //   price:,
    //   img1:,
    //   img2:,
    //   desc:,

    // }
    {
      id: 1,
      originalImage: M1,
      newImage: M4,
      bgColor: "pgreen",
    },
    {
      id: 2,
      originalImage: M3,
      newImage: M4,
      bgColor: "pgreen",
    },
    {
      id: 3,
      originalImage: M2,
      newImage: M4,
      bgColor: "pgreen",
    },
    {
      id: 4,
      originalImage: M2,
      newImage: M4,
      bgColor: "pgreen",
    },
    {
      id: 5,
      originalImage: M2,
      newImage: M4,
      bgColor: "pgreen",
    },
    {
      id: 6,
      originalImage: M2,
      newImage: M4,
      bgColor: "pgreen",
    },
  ];
  // const renderProductCards = () => {
  //   const rows = [];
  //   for (let i = 0; i < products.length; i += 3) {
  //     const row = products
  //       .slice(i, i + 3)
  //       .map((cardData, index) => <Pcard key={index} cardData={cardData} />);
  //     rows.push(
  //       <div key={i} className="flex items-center justify-center pb-10">
  //         {row}
  //       </div>
  //     );
  //   }
  //   return rows;
  // };
  const renderProductCards = () => {
    const limitedProducts = products.slice(0, 6); // Get the first 6 products

    return (
      <div className="flex flex-wrap">
        {limitedProducts.map((cardData, index) => (
          <div key={index} className="w-1/3 p-4">
            <Pcard cardData={cardData} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-bgcareer">
      <div>
        <h1 className="text-4xl font-Poppins font-semibold pt-16 flex justify-center ">
          Our Products
        </h1>
      </div>
      {/*
      popup
      form
      name,compny,phone,email,machine
  */}
      <div className="bg-bgcareer flex justify-center p-20">
        {isLoading ? (
          <Loader
            size="lg"
            content="Products Loading"
            className="justify-center"
          />
        ) : (
          renderProductCards()
        )}
      </div>
      <div>
        <div className="flex justify-center -mt-20 py-10">
          <button className="px-3 py-1 text-white hover:no-underline bg-[#003A72] ">
            <Link
              className="text-white hover:no-underline hover:text-white"
              to="ourproducts"
            >
              View More
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainProduct;
