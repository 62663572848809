import React, { useState } from "react";
import logo from "./logo.png";
// import { toast } from 'react-toastify';

const AddPrd = () => {
  const [keyValueArray, setKeyValueArray] = useState([{ key: "", value: "" }]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [millType, setMillType] = useState("");

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [myObject, setMyObject] = useState({
    name: "",
    description: "",
    title: "",
    price: "",
    data: [],
    image1: null,
    image2: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSucces, setisSucces] = useState(false);
  const [isFailed, setisFailed] = useState(false);

  const handleInputChange = (index, key, value) => {
    const updatedArray = [...keyValueArray];
    updatedArray[index] = { key, value };
    setKeyValueArray(updatedArray);
  };

  const handleAddField = () => {
    setKeyValueArray([...keyValueArray, { key: "", value: "" }]);
  };

  const handleDeleteField = (index) => {
    const updatedArray = [...keyValueArray];
    updatedArray.splice(index, 1);
    setKeyValueArray(updatedArray);
  };

  const handleFieldChange = (field, value) => {
    setMyObject((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleImageChange = (field, e) => {
    const file = e.target.files[0];
    setMyObject((prevState) => ({
      ...prevState,
      [field]: file,
    }));
  };

  var issubmittedclicked = false;
  const handleSubmit = async (event) => {
    setIsLoading(true);

    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("millType", millType);

    formData.append("image1", image1);
    formData.append("image2", image2);

    const keyValueStringArray = JSON.stringify(keyValueArray);
    //     const keyValuePairs = keyValueArray.map((item) => ({
    //     key: item.key,
    //     value: item.value,
    //   }));
    //    keyValuePairs.forEach((pair, index) => {
    //     formData.append(`key[${index}]`, pair.key);
    //     formData.append(`value[${index}]`, pair.value);
    //   });
    formData.append("keyvalue", keyValueStringArray);

    try {
      const response = await fetch("/api/addProduct", {
        method: "POST",

        body: formData,
      });
      // setisSucces(true);
      // setisFailed(true);

      if (response.ok) {
        console.log("Product created successfully");
        // Reset the form
        setIsLoading(false);
        setisSucces(true);

        // toast.success('Form submitted successfully!', {
        //   position: 'top-right',
        //   autoClose: 3000,
        //   hideProgressBar: true,
        // });

        setName("");
        setPrice("");
        setDescription("");
        setMillType("");
        setImage1(null);
        setImage2(null);
      } else {
        console.log("Failed to create product");
        setIsLoading(false);
        setisFailed(true);

        // toast.success(' Failed to create product', {
        //   position: 'top-right',
        //   autoClose: 3000,
        //   hideProgressBar: true,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closePopup = () => {
    setisSucces(false);
    setisFailed(false);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const updatedObject = { ...myObject };
  //   updatedObject.data = []; // Reset data array
  //   console.log(myObject);
  //   keyValueArray.forEach((item) => {
  //     const { key, value } = item;
  //     updatedObject.data.push({ key, value });
  //   });
  //   console.log(updatedObject);
  //   setMyObject(updatedObject);
  //   // Perform any additional actions with the filled object

  //   try {
  //     const response = await fetch("/api/addProduct", {
  //       method: "POST",
  //       body: JSON.stringify(setMyObject),
  //     });

  //     if (response.ok) {
  //       console.log("Product Added Successfully");
  //     } else {
  //       console.log("Failed to add");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   console.log(myObject);
  // };

  return (
    <div className="w-full h-[150vh] bg-[#ececec] ">
      <div className="h-[10%] bg-[#00284d] flex justify-between items-center p-5">
        <div>
          <h2 className="text-white font-bold text-2xl ml-10 font-Poppins">
            Welcome!!
          </h2>
        </div>
        <div>
          <img src={logo} alt="logo" className="h-16 w-36" />
        </div>
      </div>
      <div className="overflow-x-hidden my-8 ">
        <form
          onSubmit={handleSubmit}
          className="w-full flex justify-center items-center"
        >
          <div
            className="bg-[#00284d] rounded-md px-8 py-10 my-5 bg-opacity-90"
            style={{ boxShadow: "0 10px 12px rgba(0, 0, 0, 0.3)" }}
          >
            {/* <h3 className="text-center text-[#FBEAEB] font-Poppins ">Add Product</h3> */}
            {/* <br />
            <br /> */}
            <div>
              <h4 className="mx-14 font-bold text-[#FBEAEB] font-Poppins">
                Add a new product
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-4 px-5 mx-10 py-5">
              {/* Row 1 */}
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] mb-2 font-Poppins "
                  htmlFor="category"
                >
                  Category
                </label>
                {/* <input
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                  block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  type="text"
                  placeholder="Name"
                  value={millType}
                  onChange={(e) => setMillType(e.target.value)}
                /> */}

                <select
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  value={millType}
                  onChange={(e) => setMillType(e.target.value)}
                >
                  <option value="other">Select Category</option>
                  <option value="hotroll">Hot Rolling Machines</option>
                  <option value="slittingline">Slitting Machines</option>
                  <option value="tandem">Tandem Rolling Mills</option>
                  <option value="straightening">Straightening Machines</option>
                  <option value="coldrollrev">Cold Rolling Reversible</option>
                  <option value="cladding">Cladding Mill</option>
                  <option value="scalping">Scalping Machine</option>
                  <option value="allied">Allied Equipments</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] mb-2 font-Poppins"
                  htmlFor="desc"
                >
                  Title
                </label>
                <input
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                  block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  type="text"
                  placeholder="Title"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {/* Row 2 */}
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] mb-2 font-Poppins"
                  htmlFor="title "
                >
                  Description
                </label>
                <input
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                  block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] mb-2 font-Poppins"
                  htmlFor="desc"
                >
                  Price
                </label>
                <input
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                  block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  type="text"
                  placeholder="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              {/* Row 3 */}
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] font-Poppins my-2 "
                  htmlFor="desc"
                >
                  Product image 1
                </label>
                <input
                  className="p-2 rounded-md block w-full text-sm text-[#111827] border border-[#D1D5DB] 
                  cursor-pointer bg-[#F9FAFB] dark:text-[#9CA3AF] focus:outline-none dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] file:bg-[#4B5563] 
                  file:border-0 file:overflow-hidden  file:border-solid file:border-inherit file:-mx-3 file:-my-[0.5rem] file:px-3 file:py-2 file:text-[#c0c0c0] hover:file:text-[#111827] file:transition file:duration-150 file:ease-in-out 
                  file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-[#b1b1b1]"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage1(e.target.files[0])}
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] font-Poppins my-2 "
                  htmlFor="desc"
                >
                  Product image 2
                </label>
                <input
                  className="p-2 rounded-md block w-full text-sm text-[#111827] border border-[#D1D5DB] 
                  cursor-pointer bg-[#F9FAFB] dark:text-[#9CA3AF] focus:outline-none dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] file:bg-[#4B5563] 
                  file:border-0 file:overflow-hidden  file:border-solid file:border-inherit file:-mx-3 file:-my-[0.5rem] file:px-3 file:py-2 file:text-[#c0c0c0] hover:file:text-[#111827] file:transition file:duration-150 file:ease-in-out 
                  file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-[#b1b1b1]"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage2(e.target.files[0])}
                />
              </div>
              {/* Row 4 */}
              {keyValueArray.map((item, index) => (
                <div key={index}>
                  <div className="flex flex-col my-4 ">
                    <input
                      className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                      block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                      type="text"
                      placeholder="Key"
                      value={item.key}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, item.value)
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <input
                      className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
                      block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6] "
                      type="text"
                      placeholder="Value"
                      value={item.value}
                      onChange={(e) =>
                        handleInputChange(index, item.key, e.target.value)
                      }
                    />
                  </div>
                  <div className="flex flex-col my-4 ">
                    <button
                      className="bg-blue text-[#FBEAEB] hover:bg-[#2F3C7E] bg-none p-2 rounded-md col-span-2 border-2 border-white relative group overflow-hidden"
                      type="button"
                      onClick={() => handleDeleteField(index)}
                    >
                      <span
                        className="absolute w-1 h-1 transition-all duration-300 origin-center -translate-x-1/2 -translate-y-1/2 rotate-45
                      bg-[#1F2937] top-1/2 left-1/2 group-hover:w-64 group-hover:h-64 group-hover:-rotate-45 ease"
                      ></span>
                      <span className="relative z-10 text-white transition duration-300 group-hover:text-white ease">
                        Delete
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-2 gap-4 px-5 mx-10 py-2">
              {/* Row 5 */}
              <button
                className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] p-2 rounded-md col-span-2 border-2 border-white"
                type="button"
                onClick={handleAddField}
              >
                Add Pair
              </button>
              {/* <button
                className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] p-2 rounded-md col-span-2 border-2 border-white"
                type="submit"
                onClick={(e) => handleSubmit(e)}

              >
                Submit
              </button> */}

              <button
                className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] p-2 rounded-md col-span-2 border-2 border-white relative"
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-4 border-[#FBEAEB]"></div>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>

              {/* ---------success popup---------- */}

              {isSucces && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                  <div className="modal-container bg-white w-2/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content py-4 text-left px-6">
                      <div className="flex justify-center items-center pb-3">
                        <p className="text-2xl font-bold text-green">Success</p>
                      </div>

                      <p className="text-green text-center">
                        Product Added successfully!
                      </p>

                      <div className="flex justify-center pt-2">
                        <button
                          onClick={closePopup}
                          className="bg-black hover:bg-green z-30 text-green hover:text-white border font-bold py-2 px-4 rounded"
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isFailed && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="modal-container bg-white w-2/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content py-4 text-left px-6">
                      <div className="flex justify-center items-center pb-3">
                        <p className="text-2xl font-bold text-red">Failed</p>
                      </div>
                      <p className="text-red text-center">
                        Product not Added !
                      </p>
                      <div className="flex justify-center pt-2">
                        <button
                          onClick={closePopup}
                          className="bg-black hover:bg-red z-30 text-red hover:text-white border font-bold py-2 px-4 rounded"
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPrd;

// import React from "react";
// import logo from "./logo.png";
// import { useState } from "react";
// const AddPrd = () => {
//   const [keyValueArray, setKeyValueArray] = useState([{ key: "", value: "" }]);
//   const [myObject, setMyObject] = useState({ name: "", data: [] });

//   const handleAddInput = () => {
//     const newInput = { id: Date.now(), name: "", value: "" };
//     setInputs([...inputs, newInput]);
//   };

//   const handleInputChange = (index, key, value) => {
//     const updatedArray = [...keyValueArray];
//     updatedArray[index] = { key, value };
//     setKeyValueArray(updatedArray);
//   };

//   const handleAddField = () => {
//     setKeyValueArray([...keyValueArray, { key: "", value: "" }]);
//   };

//   const handleDeleteField = (index) => {
//     const updatedArray = [...keyValueArray];
//     updatedArray.splice(index, 1);
//     setKeyValueArray(updatedArray);
//   };
//   const handleNameChange = (e) => {
//     const name = e.target.value;
//     setMyObject((prevState) => ({
//       ...prevState,
//       name,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const updatedObject = { ...myObject };
//     updatedObject.data = []; // Reset data array

//     keyValueArray.forEach((item) => {
//       const { key, value } = item;
//       updatedObject.data.push({ key, value });
//     });

//     setMyObject(updatedObject);
//     // Perform any additional actions with the filled object
//     console.log(myObject);
//   };

//   return (
//     <div className="w-full">
//       <div className="h-[12%] bg-[#00284d] flex justify-between items-center p-5">
//         <div>
//           <h2 className="text-black font-bold text-lg ">Add Product</h2>
//         </div>
//         <div>
//           <img src={logo} className="h-16 w-36" />
//         </div>
//       </div>
//       <div className="overflow-x-hidden">
//         <form className="w-screen bg-blue-900 flex justify-center items-center ">

//           <div className="bg-blue border my-5">
//             <h1 className="text-center">Add Product</h1>
//             <br />
//             <br />
//             <div>
//               <h6 className="mx-10 font-bold">Product Information</h6>
//             </div>

//             {/* test */}
//             {/* category dropdown */}

//             <div className="px-5 mx-10 py-5 flex justify-between	">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="category">
//                   Category:
//                 </label>
//               </div>
//               <div className="flex flex-col">
//                 <input
//                   className="border"
//                   type="text"
//                   id="category"
//                   name="category"
//                   value={category}
//                 />
//               </div>
//             </div>

//             <div className="px-5 mx-10 py-5 flex justify-between	">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="title ">
//                   Title:
//                 </label>
//               </div>
//               <div className="flex flex-col">
//                 <input
//                   className="border"
//                   type="text"
//                   id="title"
//                   name="title"
//                   value={title}
//                 />
//               </div>
//             </div>

//             <div className="px-5 mx-10 py-5 flex justify-between">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="desc">
//                   Price:
//                 </label>
//               </div>
//               <div className="flex flex-col justify-self-between">
//                 <input
//                   className="border w-full"
//                   type="text"
//                   id="desc"
//                   name="desc"
//                   value={desc}
//                 />
//               </div>
//             </div>

//             <div className="px-5 mx-10 py-5 flex justify-between	">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="fname">
//                   Description:
//                 </label>
//               </div>
//               <div className="flex flex-col">
//                 <textarea
//                   className="border w-full"
//                   rows="4"
//                   cols="50"
//                   name="content"
//                   required
//                   value={content}
//                 />
//               </div>
//             </div>

//             <hr />

//             <div className="px-5 mx-10 py-5 flex justify-between	">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="desc">
//                   Product image 1
//                 </label>
//               </div>

//               <div className="flex flex-col">
//                 <input
//                   className="px-10"
//                   type="file"
//                   handleLogKeyValuePairs
//                   name="img1"
//                   value={img1}
//                 />
//               </div>
//             </div>

//             <div className="px-5 mx-10 py-5 flex justify-between	">
//               <div className="flex flex-col">
//                 <label className="text-black" htmlFor="desc">
//                   Product image 2
//                 </label>
//               </div>

//               <div className="flex flex-col">
//                 <input className="px-10" type="file" name="img2" value={img2} />
//               </div>
//             </div>

//             {inputs.map((input, index) => (
//               <div
//                 key={input.id}
//                 className="px-5 mx-10 py-2 flex justify-between"
//               >
//                 <div className="flex flex-col">
//                   <input
//                     className="border"
//                     placeholder="Property Name"
//                     value={name}
//                     onChange={(e) =>
//                       handleInputChange(input.id, "name", e.target.value)
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col">
//                   <input
//                     className="border"
//                     placeholder="Property value"
//                     value={input.value}
//                     onChange={(e) =>
//                       handleInputChange(input.id, "value", e.target.value)
//                     }
//                   />
//                 </div>
//                 <button onClick={() => handleDeleteInput(input.id)}>
//                   Delete
//                 </button>
//               </div>
//             ))}

//             {/* test */}
//             <button
//               className="p-5 mx-50 text bg-blue hover:bg-blue-dark"
//               onClick={handleAddInput}
//             >
//               + Add Property
//             </button>

//             <button
//               className="bg-blue text-black hover:bg-blue-dark"
//               onClick={handleLogKeyValuePairs}
//             >
//               PRINT ALL PROPERTIES
//             </button>
//             {/* <input type="text" id="fname" name="fname" /> */}
//             <div>
//               <submit></submit>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddPrd;
