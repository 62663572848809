import React from "react";
import Vacant from "./Vacant";
import Navbar from "../Navbar/Navbar";
const Careers = () => {
  return (
    <>
      <Navbar />
      <div className="bg-bgcareer pt-28">
        <div>
          <p className="font-Poppins  text-sm text-left rounded-full border-solid border-2 w-120 py-2 pl-4 ml-150  ">
            We're hiring!
          </p>
          <h1 className="font-Poppins text-4xl leading-60 text-left ml-150 mt-4 font-semibold">
            Be a Part of Our Team
          </h1>
          <p className="font-Poppins h-60 w-500 ml-150 leading-6 text-left mt-4 font-medium">
            We’re looking for passionate people to join our team. We value flat
            hierarchies, clear communication, and full ownership and
            responsibility.
          </p>
        </div>
        <div className="w-1170 border-t-2 border-uline ml-150 mt-20 ">
          <Vacant />
        </div>
      </div>
    </>
  );
};

export default Careers;
