import React from "react";
import Pcard from "./Pcard";
import { useState, useEffect } from "react";
import Loader from "rsuite/esm/Loader";
import M1 from "./coptech products/2 hi hot rolling mill 3.jpg";
import M2 from "./coptech products/slitting machine (2).jpg";
import M3 from "./coptech products/2 hi cold rolling mill 2.jpg";
import M4 from "./coptech products/tandam rolling mill.jpg";
import Navbar from "../Navbar/Navbar";
const OnlyProduct = () => {
  const [products, setproducts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [filter, setfilter] = useState("all");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setfilter(selectedValue);

    // Call any other function or perform additional actions here based on the selected option.
  };

  const getMachines = async () => {
    setLoading(true);
    const products = await fetch(`/api/MainProducts?filter=${filter}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await products.json();
    setproducts(data);
    setLoading(false);
  };

  useEffect(() => {
    getMachines();
  }, [filter]);

  const renderProductCards = () => {
    return (
      <div className="flex flex-wrap">
        {products.map((cardData, index) => (
          <div key={index} className="w-1/3 p-4">
            <Pcard cardData={cardData} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="bg-bgcareer pt-28 flex items-center gap-5 pl-24">
        <h4 className="font-semibold">Filter :</h4>
        <select
          value={filter}
          onChange={handleSelectChange}
          className="px-4 py-2 mt-2 text-base text-gray-700 bg-border pl-2 text-white border-2 border-grey bg-bblue1 rounded-lg focus:outline-none focus:border-blue-500"
        >
          <option value="all">All</option>
          <option value="hotroll"> Hot Rolling Machines</option>
          <option value="slittingline">Slitting Machines</option>
          <option value="tandem">Tandem Rolling Mills</option>
          <option value="straightening">Straightening Machines</option>
          <option value="coldrollrev">Cold Rolling Reversible</option>
          <option value="cladding">Cladding Mill</option>
          <option value="scalping">Scalping Machine</option>
          <option value="allied">Allied Equipments</option>
        </select>
      </div>

      <div className="bg-bgcareer flex justify-center p-24">
        {isLoading ? <Loader size="lg" /> : renderProductCards()}
      </div>
    </div>
  );
};

export default OnlyProduct;
