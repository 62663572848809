import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth/index";
import { useState } from "react";
import LoginVec from "./LoginVec.svg";
import UserProfile from "./UserProfile.svg";
import {
  LockClosedIcon,
  EnvelopeIcon,
  ArrowLeftIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const res = login({ email, password });
    if (res.auth == false) {
      navigate("/");
    }
  };
  return (
    // <div>
    //   <div className="flex items-center justify-center h-screen">
    //     <div className="bg-slate h-1/2 w-1/2 rounded-md shadow-xl">
    //       <h3 className="text-center m-8 text-lg font-semibold">Admin Login</h3>

    //       <div className="grid-rows-3 w-3/5 m-auto">
    //         <div className="grid grid-cols-2 py-5">
    //           <h4>Email</h4>
    //           <input
    //             type="text"
    //             onChange={(e) => setemail(e.target.value)}
    //             className="focus:outline-none rounded-sm p-1"
    //           />
    //         </div>
    //         <div className=" grid grid-cols-2">
    //           <h4>Password</h4>
    //           <input
    //             type="password"
    //             onChange={(e) => setPassword(e.target.value)}
    //             className="focus:outline-none rounded-sm p-1"
    //           />
    //         </div>
    //         <div>
    //           <button
    //             onClick={handleLogin}
    //             className="px-3 py-1  mx-[40%] my-10 bg-blue-600 border-white border-2 p-2"
    //           >
    //             Login
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div>
      <div
        className="flex items-center justify-center h-screen bg-"
        style={{
          background: "linear-gradient(135deg, #646D75 0%, #1F2933 100%)",
        }}
      >
        <div className="grid grid-cols-2 bg-white h-4/5 w-3/5 rounded-md shadow-xl relative">
          <div className="absolute top-4 left-8">
            <Link className="hover:no-underline" to="/">
              <button className=" flex items-center justify-center bg-blue-600 text-lgrey text-[12px] font-medium font-Poppins w-auto h-10 opacity-80 ">
                <ArrowLeftIcon className="mr-2 mb-[1px] h-3 w-3" />
                Back To Home Screen
              </button>
            </Link>
          </div>

          <img
            src={LoginVec}
            alt="Login Vector"
            className=" fill-white mx-auto my-10 w-11/12 h-11/12"
          />

          <div className="col-start-2 col-span-1 ">
            <div
              className="bg-[#fefefe] h-4/5 w-4/5 rounded-md shadow-xl"
              style={{ boxShadow: "0 10px 12px rgba(0, 0, 0, 0.25)" }}
            >
              <h3 className="text-center mt-12 mb-4 pt-12 text-lg text-[#333333] font-semibold font-Poppins">
                Admin Login
              </h3>
              <div className="flex justify-center">
                {/* <img src={UserProfile} alt="Admin Login" className=" fill-white w-20 h-20" /> */}
                <UserCircleIcon className=" fill-[#333333] w-20 h-20" />
              </div>

              {/* <hr class="w-28 h-0.5 mx-auto my-4 bg-lgrey border-0 rounded md:my-10 dark:bg-lgrey "></hr> */}
              <div className="grid-rows-3 w-3/5 m-auto">
                <div className="grid grid-cols-2 pb-5 pt-10">
                  {/* Email input field with icon */}
                  <div className="relative col-span-2">
                    <EnvelopeIcon className="absolute h-5 w-5 text-[#808080] left-3 top-2" />
                    <input
                      type="text"
                      onChange={(e) => setemail(e.target.value)}
                      className="pl-10 col-span-2 focus:outline-none w-full border- rounded-md p-2 bg-[#E8F0FE] shadow-xl hover:shadow-inner focus:shadow-inner "
                      placeholder="E-Mail"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 py-2">
                  {/* Password input field with icon */}
                  <div className="relative col-span-2">
                    <LockClosedIcon className="absolute h-5 w-5 text-[#808080] left-3 top-2" />
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="pl-10 col-span-2 focus:outline-none w-full border- rounded-md p-2 bg-[#E8F0FE] shadow-xl hover:shadow-inner focus:shadow-inner"
                      placeholder="Password"
                    />
                  </div>
                </div>
                {/* <div className="flex justify-center">
              <button
                onClick={handleLogin}
                className="grid grid-cols-2 mt-10 justify-center items-center text-center col-span-2 bg-blue-600 w-1/2 border-[#747474] rounded-md border-2 p-2"
              >
                <span className="col-span-2">Login</span>
              </button>
            </div> */}

                <div className="flex justify-center">
                  <button
                    onClick={handleLogin}
                    class="grid w-1/2 mt-10 justify-center items-center text-center col-span-2 rounded-md px-3.5 py-1 m-1 overflow-hidden 
            relative group cursor-pointer border-2 font-medium font-Poppins border-[#747474] text-white"
                  >
                    <span
                      class="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-[#747474] top-1/2 
                  group-hover:h-64 group-hover:-translate-y-32 ease"
                    ></span>
                    <span class="relative text-[#747474] transition duration-300 group-hover:text-white ease">
                      Login
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
