import React from "react";
import { Link } from "react-router-dom";

const Vacantm = () => {
  const vacancy = [
    {
      id: 0,
      title: "General Manager",
      desc: "Thank you for your interest in the General Manager position at our company.",
      time: "Full time",
      linkk: "https://forms.gle/YmDFf8GfCPQ7m5Rs8",
    },
    {
      id: 2,
      title: "Machine Operator",
      desc: "Thank you for your interest in the Machine Operator position at our company.",
      time: "Full time",
      linkk: "https://forms.gle/85N779zd9WbBSdn88",
    },
    {
      id: 3,
      title: "Mechanical Engineer",
      desc: "Thank you for your interest in the Mechanical Engineer position at our company.",
      time: "Full time",
      linkk: "https://forms.gle/HU4eYoWh2vZSAGMWA",
    },

    {
      id: 4,
      title: "AutoCAD designer",
      desc: "Thank you for your interest in the autoCAD designer position at our company",
      time: "Full time",
      linkk: "https://forms.gle/ZAyCfNhL54YLc9vF9",
    },
    {
      id: 5,
      title: "Painter",
      desc: "Thank you for your interest in the Painter position at our company",
      time: "Full time",
      linkk: "https://forms.gle/DkCShovNn8nahHBQ9",
    },
  ];
  const handleclk = (linkk) => {};
  return vacancy.map((p) => (
    <div className="w-screen overflow-x-hidden">
      <div className="mx-5 h-48 text-left pb-40 w-72 bg-bgcareer">
        <div className=" border-b-2  border-uline   mx-3 px-2 ">
          <p className="font-Poppins h-6 font-semibold text-base text-left mt-501 pt-4 pb-3 ">
            {p.title}
          </p>

          <p className="font-Poppins font-medium text-left  leading-5 mt-4">
            {p.desc}
          </p>
          <p className=" font-bold leading-5  ">{p.time}</p>

          <ul style={{ listStyleType: "none" }}>
            <li key={p.id}>
              <button
                className="    font-Poppins cursor-pointer  text-sm  rounded-full border-solid border-2 w-24 py-1 text-center  mt-2 -ml-1 mb-1"
                // onClick={() => handleclk(p.linkk)}
                id={p.id}
              >
                <a
                  className="no-underline text-rgba
                  "
                  href={p.linkk}
                  target="_blank"
                >
                  Apply
                </a>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  ));
};

export default Vacantm;
