import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth/index";
import logo2 from "./logo2.svg";
const SideBar = () => {
  const { logout } = useAuth();

  const handlelogout = () => {
    logout();
  };
  return (
    <div className="h-[100vh] w-[15%]">
      <div className="w-full h-[150vh] bg-[#003A72] p-10">
        <div className="h-20 w-32 -my-4 fill-white">
          <img src={logo2} alt="logo" className="h-24 w-44 -mx-2 fill-white" />
        </div>
        <ul className="text-center no-underline text-white py-16">
          <Link
            className=" hover:no-underline hover:text-[#003A72]"
            to="/dash"
            style={{ textDecoration: "none" }}
          >
            <li className="py-3 px-3 text-left rounded-md text-white hover:cursor-pointer my-2 font-Poppins hover:bg-white text-sm hover:text-[#003A72]">
              Home
            </li>
          </Link>
          <Link
            className=" hover:no-underline hover:text-[#003A72]"
            to="addprd"
            style={{ textDecoration: "none" }}
          >
            <li className="py-3 px-3 text-left rounded-md text-white hover:cursor-pointer my-2 font-Poppins hover:bg-white  text-sm hover:text-[#003A72]">
              Add Product
            </li>
          </Link>

          <Link
            className=" hover:no-underline hover:text-[#003A72]"
            to="addcar"
            style={{ textDecoration: "none" }}
          >
            <li className="py-3 px-3 text-left rounded-md text-white hover:cursor-pointer my-2 font-Poppins hover:bg-white  text-sm hover:text-[#003A72]">
              Job Applications
            </li>
          </Link>
          <li
            onClick={handlelogout}
            className="py-3 px-3 text-left rounded-md my-10 hover:cursor-pointer text-sm font-Poppins hover:bg-white hover:text-[#003A72]"
            style={{ textDecoration: "none" }}
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
