import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import backgroundImage from "./image/cpr.webp";
import Popup from "./popup";

const ContactComponent = () => {
  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    content: "",
    fname: "",
    lname: "",
    email: "",
    city: "",
    phone: "",
    company: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email format
    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Validate phone number format
    if (!validatePhoneNumber(formData.phone)) {
      alert("Please enter a valid phone number.");
      return;
    }

    setShowPopup(true);
    console.log(formData);
    const rep = await fetch("/api/postqueries", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const val = await rep.json();
    console.log(val);
    handleReset();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleReset = () => {
    setFormData({
      content: "",
      fname: "",
      lname: "",
      email: "",
      city: "",
      phone: "",
      company: "",
    });
  };

  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const validateEmail = (email) => {
    // Simple email format validation
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    // Simple phone number format validation
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  return (
    <div
      className={`flex flex-col md:flex-row items-center bg-cover p-5 md:p-20 ${
        isMobile ? "pb-20 h-screen" : ""
      }`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: isMobile ? "" : "80vh",
      }}
    >
      <div
        className={`flex-1 ${isMobile ? "mb-0 md:mb-0 text-center" : "mr-10"}`}
      >
        <h1
          className={`text-[#ECECEC] font-bold lg:ml-[150px] ${
            isMobile ? "text-4xl mt-10" : "text-6xl"
          }`}
          style={{ textAlign: isMobile ? "center pb-10" : "left" }}
        >
          Get help
          {isMobile ? " " : <br />}
          with our
          {isMobile ? " " : <br />}
          Team
        </h1>
        <br />
        <p
          className={`text-[#ECECEC] text-lg lg:ml-[150px] ${
            isMobile ? "mb-6" : ""
          } `}
          style={{ textAlign: isMobile ? "center" : "left" }}
        >
          Our Team will guide you...
        </p>
      </div>
      <div
        className={`flex-1 bg-[#ECECEC] bg-opacity-30 p-5 ${
          isMobile ? "" : "ml-10"
        }`}
        style={{ width: "80%" }}
      >
        <form
          onSubmit={handleSubmit}
          onReset={handleReset}
          style={{ width: "100%" }}
        >
          <div className={`mb-3 ${isMobile ? "space-y-2" : "space-x-2"}`}>
            <textarea
              className={`bg-[#ECECEC] bg-opacity-40 w-full p-2 py-2 px-4 text-[#ECECEC] placeholder-[#ECECEC] border-none focus:border-transparent focus:outline-none`}
              placeholder="What do you want to ask?"
              rows="4"
              cols="30"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
            />
          </div>
          <div
            className={`flex flex-col md:flex-row mb-3 ${
              isMobile ? "space-y-2 md:space-y-0" : "space-x-2"
            }`}
          >
            <input
              type="text"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] border-none  focus:outline-none focus:border-transparent`}
              placeholder="First Name"
              name="fname"
              value={formData.fname}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] focus:outline-none border-none focus:border-transparent`}
              placeholder="Last Name"
              name="lname"
              value={formData.lname}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="mb-3">
            <input
              type="email"
              className="bg-[#ECECEC] bg-opacity-40 w-full p-2 text-dgrey placeholder-[#ECECEC] border-none focus:border-transparent"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div> */}
          <div
            className={`flex flex-col md:flex-row mb-3 ${
              isMobile ? "space-y-2 md:space-y-0" : "space-x-2"
            }`}
          >
            <input
              type="email"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] focus:outline-none border-none focus:border-transparent`}
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] focus:outline-none border-none focus:border-transparent`}
              placeholder="Company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>
          <div
            className={`flex flex-col md:flex-row mb-3 ${
              isMobile ? "space-y-2 md:space-y-0" : "space-x-2"
            }`}
          >
            <input
              type="text"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] focus:outline-none border-none focus:border-transparent`}
              placeholder="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className={`bg-[#ECECEC] bg-opacity-40 w-full md:w-1/2 p-2 text-[#ECECEC] placeholder-[#ECECEC] focus:outline-none border-none focus:border-transparent`}
              placeholder="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              type="submit"
              className="bg-[#F0CB20] text-[#1E2C3B] py-2 px-4 font-semibold shadow-lg 
              hover:bg-[#333333] focus:bg-[#333333] hover:text-[#ECECEC] focus:text-[#ECECEC] focus:outline-none mx-auto rounded"
            >
              Send Enquiry
            </button>
          </div>
        </form>
        {showPopup && <Popup onClose={handleClosePopup} />}
      </div>
    </div>
  );
};

export default ContactComponent;
