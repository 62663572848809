import React from "react";
import certificate from "./iso.webp";
import badge from "./isobadge.webp";
import {
  AiOutlineFileText,
  AiOutlineSwap,
  AiOutlineDollar,
  AiOutlineSolution,
  AiOutlineBarcode,
  AiOutlineBank,
  AiFillFacebook,
} from "react-icons/ai";
import { useMediaQuery } from "react-responsive";

const CompanyDetailR = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <div className={`bg-white pb-20 ${isDesktop ? "ml-10" : "px-4 mx-2"}`}>
      <h1
        className={`text-3xl font-bold ${isDesktop ? "mt-20 mx-16" : "mt-10 text-center"
          }`}
      >
        Company Details
      </h1>
      <div className={`flex ${isDesktop ? "ml-10" : "flex-col items-center "}`}>
        <div
          className={`flex-1 ${isDesktop ? "border-r pr-4" : " w-screen border-b pb-2 mx-5"
            }`}
        >
          <div className="flex">
            <div className={`flex-1  ${isDesktop ? "pt-20" : "pt-5 flex-col"}`}>
              <div className="flex items-center p-5">
                <AiOutlineFileText
                  className={` ${isDesktop ? "text-2xl" : "text-2xl "}`}
                />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl"
                      }`}
                  >
                    Nature of Business
                  </h3>
                  <p>Exporter and Manufacturer</p>
                </div>
              </div>
              <div className="flex items-center p-5">
                <AiOutlineSwap
                  className={` ${isDesktop ? "text-2xl" : "text-2xl"}`}
                />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl"
                      }`}
                  >
                    Import Export Code
                  </h3>
                  <p>AAKCC1447Q</p>
                </div>
              </div>
              <div className="flex items-center p-5">
                <AiFillFacebook className="text-2xl" />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl"
                      }`}
                  >
                    Founder
                  </h3>
                  <p
                  >Mr. S.S. Vaidya</p>
                </div>
              </div>
            </div>
            <div className={`flex-1  ${isDesktop ? "pt-20" : "pt-5 flex-col"}`}>
              <div className="flex items-center p-5">
                <AiOutlineBank className="text-2xl" />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl"
                      }`}
                  >
                    Year of Establishment
                  </h3>
                  <p>2010</p>
                </div>
              </div>
              <div className="flex items-center p-5">
                <AiOutlineSolution className="text-2xl" />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl mt-6"
                      }`}
                  >
                    Legal Status of Firm
                  </h3>
                  <p>Limited Company (Ltd./Pvt.Ltd)</p>
                </div>
              </div>
              <div className="flex items-center p-5">
                <AiOutlineBarcode className="text-2xl" />
                <div className="ml-2">
                  <h3
                    className={` ${isDesktop ? "text-2xl font-bold" : "text-xl "
                      }`}
                  >
                    GST NO
                  </h3>
                  <p>23AAKCC1447Q1ZC</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${isDesktop
            ? "flex-1 flex flex-row pr-10"
            : "flex flex-col items-center"
            }`}
        >
          <div className="flex-1 flex flex-col items-center justify-center">
            <div
              className={`${isDesktop ? "text-center" : "flex justify-center items-center"
                }`}
            >
              <img src={badge} alt="Logo" className="w-13" />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center">
            <div
              className={`${isDesktop ? "text-center" : "flex justify-center items-center"
                }`}
            >
              <img src={certificate} alt="Certificate" className="w-13 mr-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailR;
