import React, { useState, useEffect, useRef } from "react";
import "../../index.css";

const Video1 = () => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [val, setval] = useState("");
  const [hvdata, sethv] = useState("");
  const handleHover = (e) => {
    setval(e.target.firstChild.data);
    console.log(val);
    if (val === "Tandem Rolling Mills") {
      sethv(buttonData[5].content);
    } else if (val === "Hot Rolling Machines") {
      sethv(buttonData[0].content);
    } else if (val === "Straightening Machines") {
      sethv(buttonData[2].content);
    } else if (val === "Slitting Machines") {
      sethv(buttonData[3].content);
    } else if (val === "Cold Rolling Machines") {
      sethv(buttonData[1].content);
    } else if (val === "Foil Rewarding Machines") {
      sethv(buttonData[4].content);
    }
    setIsHovered(true);
  };
  const buttonData = [
    {
      content:
        "Hot rolling is a metalworking process in which metal is heated above the re-crystallization temperature to plastically deform it in the working or rolling operation. This process is used to create shapes with the desired geometrical dimensions and material properties while maintaining the same volume of metal. The hot metal is passed between two rolls to flatten it, lengthen it, reduce the cross-sectional area and obtain a uniform thickness.",
    },
    {
      content:
        "Cold rolling is a process by which metal is passed through rollers at temperatures below its re-crystallization temperatures. The metal is compressed and squeezed, increasing the yield strength and hardness of the metal. Cold rolling of metal strip is a special segment within the metalworking industry.",
    },
    {
      content:
        "We are a leading Manufacturer of automatic mild steel straightening machine, metal straightening machine and straightening machines from Indore, India.",
    },
    {
      content:
        "Manufacturer of a wide range of products which include metal slitting machine.",
    },
    {
      content:
        "We are a leading Manufacturer of foil rewinding machine from Indore, India.",
    },
    {
      content:
        "A tandem rolling mill is a rolling mill with two or more close-coupled stands, where the reduction is achieved by the inter-stand tension and the compressive force between the work rolls. Our company is renowned as a pioneer name in the industry, engaged in bringing forth an assorted gamut of Tandem Rolling Mills.",
    },
  ];
  const Customgrad = {
    background: "linear-gradient(235deg, #003A72 2.39%, #010101 87.96%)",
  };

  //----autoplay---
  const videoRef = useRef(null);

  useEffect(() => {
    // Play the video when the component mounts
    videoRef.current.play();
  }, []); //---

  //   const Custbox = {
  //     background: "#FFAD00;",
  //   };
  //  const handleClick = () => {
  //     setIsClicked(!isClicked);
  //   };
  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const togglePlay = () => {
  //   const video = videoRef.current;
  //   if (isPlaying) {
  //     video.pause();
  //   } else {
  //     video.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };
  const HoverLabel = () => {
    useEffect(() => {}, [hvdata]);
    return (
      <div class="p-4 -mt-96 ml-50 w-80 rounded-md shadow-xl bg-transparent backdrop-blur-lg   m-card  left-44 border-2 border-white bg-fgrey bg-opacity-30">
        <div class="max-w-sm">
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 text-white font-outfit"></div>
            <p class="text-slate-202 text-base font-outfit text-white">
              {hvdata}
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    //  Main Text
    <div class="">
      <div
        class=" h-screen md:h-screen md:w-screen sm:h-screen sm:w-screen flex justify-center"
        style={Customgrad}
      >
        <div className=" pt-48 mr-24 ">
          <div class="">
            <h1 class="text-white font-semibold text-5xl font-outfit mb-5 align-text-top top-40 left-40">
              Leading Machine<br></br> Manufacturer In <br></br> Indore
            </h1>
          </div>
          <div class="text-white font-normal text-xl font-outfit   align-text-top top-330 left-40">
            <p>We deal in</p>
          </div>

          {/* Buttons */}
          <div class="flex-nowrap w-auto justify-center items-center h-auto">
            <div className="flex justify-center items-center">
              <div>
                <div className="flex">
                  {console.log()}
                  <button
                    class="px-4 m-1 py-2 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top top-330 left-16 md:top-md330"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Tandem Rolling Mills
                  </button>

                  <button
                    class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top top-330  left-16 md:top-md330"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Hot Rolling Machines
                  </button>
                </div>
                <div className="flex">
                  <button
                    class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top sm:top-301 sm:left-16 top-302 left-16 md:top-md301"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Straightening Machines
                  </button>

                  <button
                    class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
             align-text-top sm:top-301 sm:left-62 top-303 left-16 md:top-md301"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Slitting Machines
                  </button>
                </div>
                <div className="flex">
                  <button
                    class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
            align-text-top sm:top-302 sm:left-16 top-304 left-16 md:top-md303"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Cold Rolling Machines
                  </button>

                  <button
                    class="px-4 py-2 m-1 text-sm font-normal font-outfit text-white bg-greybutton bg-opacity-60 
           hover:bg-opacity-100 transition-all duration-330 transform hover:scale-110 hover:text-black 
             align-text-top sm:top-302 sm:left-md61 top-305 left-16 md:top-md303"
                    onMouseMove={(e) => handleHover(e)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleClick}
                  >
                    Foil Rewarding Machines
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Video  */}
        <div className="">
          <div className="aspect-auto  px-4 py-2  h-96   top-40  bottom-96 ml-12 pt-48">
            <div className="drop-shadow-2xl filter bg-black ">
              <video
                ref={videoRef}
                class="w-full h-96 rounded-sm"
                controls
                autoPlay
                muted
              >
                <source
                  src={
                    "https://ik.imagekit.io/coptech/CoptechVideo?updatedAt=1689878168432"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>

      {/* Hover Card */}
      {isHovered && <HoverLabel />}
      {isHovered && hoveredButton && (
        <div className="p-4 -mt-96 ml-50 w-80 rounded-md shadow-xl bg-transparent backdrop-blur-lg m-card left-44 border-2 border-white">
          <div className="max-w-sm">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-white font-outfit">
                {hoveredButton.label}
              </div>
              <p className="text-slate-202 text-base font-outfit text-white">
                {hoveredButton.content}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video1;
