import React from "react";
import Navbar from "./component/Navbar/Navbar";
import AppMain from "./component/Main/AppMain";
import { Router, Routes, Route, Navigate } from "react-router-dom";
import SinglePr from "./component/ProductCarousel/SinglePr";
import Gallery from "./component/Gallery/Gallery";
import Dashboard from "./component/Admin/Dashboard";
import ProduchMob from "./component/ProductCarousel/ProductMob";

import Login from "./component/Admin/Login";
import AddPrd from "./component/Admin/AddPrd";
import OnlyProduct from "./component/ProductCarousel/OnlyProduct";
import AboutPage from "./component/AboutUs/AboutUsPage";
import Careers from "./component/Career/Careers";
import Sample from "./component/Sample";
import CareesMob from "./component/Career/CareesMob";
import GalleryMob from "./component/Gallery/GalleryMob";
import Slideshow1 from "./component/slideshow/Slideshow1";
import AboutUsMobile from "./component/AboutUs/AboutUsMobile";
import FooterMob from "./component/Footerm/FooterMob";
import Client from "./component/ClientInfo/Client";
import ProductDet from "./component/ProductCarousel/ProductDet";
// import Loader from "rsuite/Loader";
import Sample2 from "./component/Sample2";
import { ProtectRoutes } from "./hooks/protected";
function App() {
  const admin = localStorage.getItem("isLogged");
  return (
    <div className="App">
      <div>
        <Routes>
          <Route exact path="/" element={<AppMain />} />
          <Route exact path="/login" element={<Login />} />
          <Route element={<ProtectRoutes />}>
            <Route path="/dash/*" element={<Dashboard />} />
          </Route>
          <Route exact path="/ourproducts" element={<OnlyProduct />} />
          <Route exact path="/aboutus" element={<AboutPage />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route path="/single">
            <Route path=":id" element={<SinglePr />} />
          </Route>
          <Route exact path="/gallerym" element={<GalleryMob />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/careersm" element={<CareesMob />} />
          <Route exact path="/productm" element={<ProductDet />} />
          <Route exact path="aboutm" element={<AboutUsMobile />} />
          <Route exact path="/single" element={<SinglePr />} />
          <Route exact path="/Slide" element={<Slideshow1 />} />
          <Route exact path="/footerm" element={<FooterMob />} />
          <Route exact path="/ourclients" element={<Client />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
