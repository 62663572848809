import React from "react";
import { FaBars } from "react-icons/fa";
import { Link as Li } from "react-scroll";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { scrollIntoView } from "scroll-into-view";

import { useRef } from "react";

const NavbarMobile = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/footerm") {
      scrollToFooter();
    }
  }, [location]);

  const scrollToFooter = () => {
    const footerElement = document.getElementById("footer");
    scrollIntoView(footerElement, { behavior: "smooth" });
  };
  return (
    <div>
      <div
        className=" bg-blackrgba 
            fixed left-5 bottom-5 right-5 rounded-md"
      >
        <div
          className=" p-1 h-14
            flex justify-center items-center
            text-white text-2xl 
            gap-2 bg-[#003A72]   m-2 rounded-md
             " // fgrey
        >
          <div className="flex bg-[#003A72]   justify-center items-center  text-2xs flex-1 h-10 rounded-md font-Poppins text-white font-medium">
            <Link
              to="/"
              className="text-white  visited:text-white flex justify-center items-center"
            >
              Home.
            </Link>
            <FaBars
              className="justify-center items-center  text-2xs ml-0.5"
              style={{ color: "#ffffff" }}
            />
          </div>
          <div className="border-2 flex justify-center items-center border-lgrey hover:border-white bg-[#003A72]    text-2xs/3 flex-1 h-10 rounded-md font-Poppins text-white p-1 font-medium">
            <Link
              className="text-white  visited:text-white flex justify-center items-center"
              to="/productm"
            >
              PRODUCTS
            </Link>
          </div>

          <div className="border-2 flex justify-center items-center border-lgrey bg-[#003A72]    hover:border-white text-2xs/3 flex-1 h-10 rounded-md font-Poppins text-white p-1 font-medium">
            <Link
              className="text-white visited:text-white flex justify-center items-center"
              to="/ourclients"
            >
              CUSTOMERS
            </Link>
          </div>
          <div className="border-2 flex justify-center items-center border-lgrey bg-[#003A72]   hover:border-white text-2xs/3 flex-1 p-1 h-10 rounded-md font-Poppins text-white font-medium">
            <Link
              className="text-white visited:text-white flex justify-center items-center"
              to="/gallerym"
            >
              GALLERY
            </Link>
          </div>
          <div className="border-2 flex justify-center items-center border-lgrey bg-[#003A72]   hover:border-white text-2xs/3 flex-1 p-1 h-10 rounded-md font-Poppins text-white font-medium ">
            <Li to="footer" spy={true} smooth={true}>

              <Link
                className="text-white visited:text-white flex justify-center items-center"
                to="/"
              // onClick={scrollToFooter}
              >
                REACH US
              </Link>
            </Li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
