import React from "react";
import background from "./images/aboutusbg.webp";
import rajwada from "./images/rajwada.png";
import "../../index.css";
import ssvaidya from "./images/ssvaidya.jpeg";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
const AboutPage = () => {
  return (
    <div>
      <Navbar />
      {/* <div
        className="flex flex-col md:flex-row items-center bg-cover bg-center h-[771px] md:h-[740px] lg:h-[771px] w-[100%] bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="w-full h-70vh flex flex-col items-center justify-center">
          <header className="text-center">
            <h1 className="text-8xl font-bold text-white mt-48 font-serif">
              About Us
            </h1>
          </header>
          <p className="font-bold text-white font-serif text-2xl mt-44 md:mt-24 lg:mt-44">
            Explore Our Products
          </p>

          <button className="border-2 w-32 h-10 text-white  border-white rounded-[20px]  font-serif flex justify-center  mt-12  items-center text-2xl font-bold bg-transparent hover:bg-[#6a4736]">
            {" "}
            <Link
              className="font-semibold text-white hover:text-white flex justify-center items-center"
              to="/ourproducts"
            >
              Here
            </Link>
          </button>
        </div>
      </div> */}
      <div className="w-full h-50vh flex flex-col items-center justify-center">
        <header className="text-center">
          <h1 className="text-5xl font-bold text-black mt-40 font-serif underline">
            About Us
          </h1>
        </header>
      </div>

      <div className="flex flex-col items-center justify-center ">
        <h2 className="text-4xl font-bold mt-5 font-serif">
          {" "}
          Indore's Finest Manufacturer Of Machines For{" "}
        </h2>
        <p className="font-bold mt-4 text-[#905A26] text-3xl font-serif">
          {" "}
          Copper, Brass, Aluminium, Steel, Processing Rolling Mills & Allied
          Equipment
        </p>
      </div>

      <div
        className="w-full h-[600px] md:h-[800px] lg:h-[564px] bg-gradient-to-br flex mt-12 container mx-0"
        style={{
          background: "linear-gradient(90deg, #B8C9CF 5%, #FFFFFF 60%)",
          "@media (max-width: 768px)": {
            background: "linear-gradient(90deg, #B8C9CF 5%, #FFFFFF 80%)",
          },
        }}
      >
        <div className="w-full h-[800px] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 justify-items-start">
          <div className="h-full col-span-2 mx-40 w-[80%]">
            <p className="mt-20 text-left font-serif text-[#333333] text-[36px]">
              {" "}
              OUR HISTORY{" "}
            </p>
            <p
              className="mt-5 -ml-20 pb-20 text-justify font-serif text-[#333333]"
              style={{ fontSize: "18px" }}
            >
              <b>"COPTECH ENGINEERING PRIVATE LIMITED"</b> is backed by its
              founder company, <b>COPTECH ENGINEERING.</b>
              <br></br>
              <br></br>
              COPTECH ENGINEERING was founded by <b>Mr. S.S. Vaidya </b> in 2010
              with the idea of producing high-quality rolling equipment,
              specifically designed for copper, brass, and aluminum. The primary
              goal was to manufacture top-notch rolling and associated equipment
              within the most economical price range. Over the years, we have
              successfully achieved this objective, allowing us to gracefully
              remain in the market for the past 13 years. We are now ISO
              certified and recognized by the Government of India, earning the
              trust of many giant metal processing industries.
              <br></br>
              <br></br>
              Year after year, we continue to expand and update our product line
              with the latest technological advancements to deliver the best
              products that meet and exceed our clients' expectations.
              <br></br>
              We are supported by a highly technical team with in-depth
              knowledge in the production of rolling and allied equipment."
              <br></br>
            </p>
          </div>

          <div className="h-[200px] md:h-[100px] lg:h-[150px] md:h col-span-1 mx-40 w-[70%] md:w-[40%] lg:w-[70%] mt-[210px] ml-12 flex items-center">
            <img
              className="transition-transform duration-200 transform hover:scale-95 drop-shadow-[0_50px_50px_rgba(0,0,0,0.5)]"
              src={rajwada}
              alt="rajwada fort"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-200 h-[100vh] md:h-[150vh] lg:h-[110vh] w-full">
        <div className="contsiner mx-0 w-full h-[200px] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 justify-items-start">
          <div className="h-[40%] md:h-[90%] lg:h-[350px] col-span-1 mx-15 md:mx-50 lg:mx-10 w-[90%] mt-44 flex flex-col items-center">
            <img
              className="w-[60%] md:w-[40%] lg:w-[217.15px] h-[325.73px] mt-2 bg-bgrey drop-shadow-[0_25px_25px_rgba(0,0,0,0.5)] text-black text-opacity-50"
              src={ssvaidya}
              alt="SS Vaidya"
            />
          </div>

          <div className="h-[800px] col-span-2 mx-60 w-[75%]">
            <h1
              className="mt-20 text-left font-serif text-[#333333] "
              style={{ fontSize: "2rem" }}
            >
              {" "}
              Why Choose us?{" "}
            </h1>
            <p
              className="mt-16 text-justify font-serif text-[#333333]"
              style={{ fontSize: "18px" }}
            >
              Integrating technology, engineering expertise and rich industry
              experience, has helped Coptech Engineering in meeting the exact
              needs and requirements of the customers. We are profound
              manufacturer, supplier and exporter in the field of industrial
              mills. In our wide range, we have introduced Cold Rolling Mills,
              Tandem Type Rolling Mills, Hot Rolling Mills, Slitting Machines,
              Straightening Machines and others. All these products are
              identified with salient features like functional soundness,
              enhanced durability, scratch resistant, high performance, easy
              user interface, etc.
            </p>
            <p
              className="mt-20 font-serif text-[#333333]"
              style={{
                marginTop: "2rem",
                textAlign: "justify",
                fontSize: "18px",
              }}
            >
              Our products are quality tested and provide complete value for
              money. Besides, we ensure timely delivery of the products through
              our convenient supply channels. Owing to the reliable usability of
              our products and our strong business dynamics, we have carved a
              niche for ourselves in this competitive market. Also, we are one
              of the most preferred service providers of Turn Key/Consultancy
              for setting up complete plants for Copper Melting and casting
              plants, Non Ferrous Rolling Plants, Copper Extrusion plants, etc.
            </p>
            <p className=" font-AlexBrush text-[30px] font-bold  text-[#333333] mt-8">
              {" "}
              - S.S. Vaidya
            </p>
            {/* <p className=' font-AlexBrush text-[30px] text-[#333333] ml-6'> (Director)</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
