import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Pcard from "./Pcard";
import M1 from "./coptech products/2 hi hot rolling mill 3.jpg";
import M2 from "./coptech products/slitting machine (2).jpg";
import M3 from "./coptech products/2 hi cold rolling mill 2.jpg";
import M4 from "./coptech products/tandam rolling mill.jpg";
import NavbarMobile from "../Navbar/NavbarMobile";
import MobileTop from "../Navbar/MobileTop";

const ProduchMob = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [products, setproducts] = useState([]);

  const getMachines = async () => {
    const products = await fetch("/api/ourProducts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await products.json();
    setproducts(data);
  };
  useEffect(() => {
    getMachines();
  }, []);
  const renderProductCards = () => {
    return products.map((cardData, index) => (
      <div key={index} className="flex justify-center mb-9 ">
        <Pcard key={index} cardData={cardData} />
      </div>
    ));
  };

  return (
    <div className="bg-bgcareer ">
      <div>
        <MobileTop />
      </div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop={false}
        selectedItem={currentSlide}
        scrollVertical={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        {renderProductCards()}
      </Carousel>

      <NavbarMobile />
    </div>
  );
};

export default ProduchMob;
