import React from "react";
import SideBar from "./SideBar";
import MainDash from "./MainDash";
import AddPrd from "./AddPrd";
import AddCar from "./AddCar";
import AddImg from "./AddImg";
import Sample2 from "../Sample2";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <div className="flex">
      <SideBar />
      <Routes>
        <Route path="/" element={<MainDash />} />
        <Route path="/addprd" element={<AddPrd />} />
        <Route path="/addimg" element={<AddImg />} />
        <Route path="/addcar" element={<AddCar />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
