import React from "react";
import { useEffect, useState } from "react";
import NavbarMobile from "../Navbar/NavbarMobile";
import MobileTop from "../Navbar/MobileTop";
const GalleryMob = () => {
  const customStyles = {
    background: "-moz-linear-gradient(top, #ececec 50%, #112437 50%)",
    background: "-webkit-linear-gradient(top, #ececec 50%,#112437 50%)",
    background: "linear-gradient(to bottom, #ececec 50%,#112437 50%)",
  };

  const images = [
    "https://images.unsplash.com/photo-1461696114087-397271a7aedc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1523510468197-455cc987be86?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1504253492562-cbc4dc540fcb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, images]);

  const backgroundImage = `url(${images[currentImageIndex]})`;

  const divStyle = {
    backgroundImage,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>
      <MobileTop />

      <div className="mt-24 pb-60">
        <div style={customStyles}>
          <div className="h-750 grid grid-cols-4 gap-5 grid-rows-8 p-4">
            <div className="col-span-2 row-span-2 row-start-1 col-start-1 flex flex-col font-semibold justify-end -mt-24   bg-cover bg-center font-Poppins">
              <p className="text-6xl">Xplore</p>
              <p className="text-6xl">Coptech</p>
            </div>
            <div className="col-span-2 row-span-2 col-start-1 row-start-3 bg-bgimage2 bg-cover mt-5  bg-center"></div>
            <div className="col-span-2 row-span-2 col-start-3 row-start-3  bg-bgimage3 bg-cover  bg-center"></div>
            <div
              className="col-span-3 row-span-3 col-start-2 row-start-5 -mb-8  bg-cover  bg-center"
              style={divStyle}
            ></div>
            <div className="col-span-1 row-span-4 col-start-1 row-start-8 bg-cover font-Poppins  bg-center flex flex-col justify-end">
              <h1 className="text-7xl -mt-24 text-bgcareer">Gallery</h1>
            </div>
          </div>
          <div className="flex flex-col flex-shrink-0 w-294 h-78 items-center p-10 text-bgcareer font-Poppins">
            <p>
              Coptech Engineering is an ISO 9001-2008 certified company and a
              Government of India recognized export & domestic house, a
              well-organized & well-equipped engineering company situated on the
              91 a industrial area no-1 a.b. Road Indore (India), one of the
              place consisting maximum number of copper/brass/aluminum/ steel
              producing mills in India.
            </p>
          </div>
        </div>
        <NavbarMobile />
      </div>
    </>
  );
};

export default GalleryMob;
