import React from "react";
import { useEffect, useState } from "react";
import bgimage1 from "../ProductCarousel/coptech products/6 hi rolling mill (2).jpg";
import Navbar from "../Navbar/Navbar";

const Gallery = () => {
  const customStylesbg = {
    background:
      "linear-gradient(to right, #AEC6CF 0%, #AEC6CF 70%, #ececec 70%, #ececec 100%)",
  };

  const images = [
    "https://images.unsplash.com/photo-1461696114087-397271a7aedc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1523510468197-455cc987be86?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1504253492562-cbc4dc540fcb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, images]);

  const backgroundImage = `url(${images[currentImageIndex]})`;

  const divStyle = {
    backgroundImage,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div>
      <Navbar />
      <div style={customStylesbg} className="pb-20 pt-150">
        <div className="flex justify-center items-center text-3xl leading-5011 mb-5011 font-Poppins font-extrabold">
          <h1>Gallery</h1>
        </div>
        <div className="flex justify-center items-center mb-5011 font-Poppins">
          <p>Explore the world of Coptech.</p>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-1130 h-1143 grid grid-cols-6 gap-5 grid-rows-10 ">
            <div className="col-span-2 row-span-3 row-start-1 col-start-1   bg-bgimage1 bg-cover bg-center"></div>
            <div className="col-span-2 row-span-3 col-start-3 row-start-1 bg-bgimage2 bg-cover  bg-center"></div>
            <div className="col-span-2 row-span-2 col-start-5 row-start-1  bg-bgimage3 bg-cover  bg-center"></div>
            <div className="col-span-2 row-span-2 col-start-5 row-start-3 bg-bgimage4 bg-cover  bg-center"></div>
            <div
              className="col-span-4 row-span-3 col-start-1 row-start-4"
              style={divStyle}
            ></div>
            <div className="col-span-2 row-span-2 col-start-5 row-start-5 bg-bgimage6 bg-cover  bg-center"></div>
            <div className="col-span-3 row-span-4 col-start-1 row-start-7 bg-bgimage7 bg-contain  bg-center"></div>
            <div className="col-span-3 row-span-4 col-start-4 row-start-7 bg-bgimage8 bg-contain  bg-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
