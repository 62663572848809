import React from 'react';


const Company = (props) => {
  return (
    <div className='flex flex-col items-center  pb-10 hover:pb-0  '>
      <div className="w-200 h-200 hover:w-250 hover:h-250 px-5 bg-[#f0f3f7] rounded-lg ">
        <img src={props.logo} alt='Company Logo' className="h-full w-full object-contain" />
      </div>
      <h4 className='hover:text-2xl hover:text-[#313b84]  font-semibold mt-2 transition-colors duration-300'>{props.name}</h4>
    </div>
  );
}

export default Company;
