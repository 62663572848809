import React from "react";
import background from "./images/aboutusbg.webp";
import rajwada from "./images/rajwada.png";
import "./About.css";
import ssvaidya from "./images/ssvaidya.jpeg";
import NavbarMobile from "../Navbar/NavbarMobile";
import { Link } from "react-router-dom";
import MobileTop from "../Navbar/MobileTop";

const AboutUs = () => {
  return (
    <div className="bg-[#ECECEC]">
      <div>
        <MobileTop />
      </div>

      {/* <header
        className="relative h-[450px] bg-cover bg-center bg-no-repeat bg-header-image sm:bg-header-image-sm "
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="h-full flex flex-col justify-center items-center">
          <h1 className="text-4xl font-black text-white text-center mb-8 md:text-[48px] font-serif">
            About Us
          </h1>
          <div className="absolute bottom-0 left-0 right-0">
            <p className="text-[16px] font-bold text-white text-center font-serif">
              Explore Our Products
            </p>
            <button className="mt-4 mb-6 px-6  text-white bg-none rounded-[20px] font-serif border-white border-2 hover:bg-[#6a4736] mx-auto block">
              <Link
                className="text-white hover:no-underline hover:text-white md:hidden"
                to="/productm"
              >
                Here
              </Link>
            </button>
          </div>
        </div>
      </header> */}
      {/* <div className="h-full flex flex-col mt-48 justify-center items-center">


      </div> */}
      <div className="grid grid-cols-1 pt-20 pb-6 mt-20 bg-[#ECECEC] px-2 text-center font-bold font-serif text-stroke text-stroke-white">
        <p className="text-4xl font-black text-black text-center mb-4 mt-30 md:text-[48px] font-serif">
          About Us
        </p>

        <p className="text-[14px]">
          Indore's Finest Manufacturer of Machines For
        </p>
        <p className="text-[#905A26] text-[14px]">
          Copper, Brass, Aluminium, Steel, Processing Rolling Mills & Allied
          Equipment
        </p>
      </div>
      <div className="relative">
        <div className="h-[480px] md:h-[350px] relative">
          <img
            className="absolute inset-0 object-cover w-full h-full opacity-30"
            src={rajwada}
            alt="Background"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="h-full flex flex-col items-center relative z-1">
            <h1 className="text-xl font-black text-[#333333] text-center mt-5 font-serif animate-slide-left ">
              Our History
            </h1>
            <p
              className="text-[12px] leading-5 font-semibold text-[#333333] text-left pl-6 pr-4 mb-10 mt-6 pb-10
      md:mr-32 md:ml-32 font-Poppins animate-slide-left "
            ><b>
                "COPTECH ENGINEERING PRIVATE LIMITED" </b>is backed by its founder
              company, <b>COPTECH ENGINEERING</b>.
              <br></br>
              COPTECH ENGINEERING was founded by <b>Mr. S.S. Vaidya </b> in 2010 with
              the idea of producing high-quality rolling equipment, specifically
              designed for copper, brass, and aluminum. The primary goal was to
              manufacture top-notch rolling and associated equipment within the
              most economical price range. Over the years, we have successfully
              achieved this objective, allowing us to gracefully remain in the
              market for the past 13 years. We are now ISO certified and
              recognized by the Government of India, earning the trust of many
              giant metal processing industries.
              <br></br>
              Year after year, we continue to expand and update our product line
              with the latest technological advancements to deliver the best
              products that meet and exceed our clients' expectations.
              <br></br>
              We are supported by a highly technical team with in-depth
              knowledge in the production of rolling and allied equipment."
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 grid-rows-4 h-[600px] bg-gradient-to-r from-[#96acb3] to-[#FFFFFF]">
        <h1
          className="grid grid-cols-1 text-[14px] font-semibold text-[#333333] text-justify pl-6 pr-4 mt-12 
  font-serif md:ml-16 animate-slide-left "
        >
          Why Choose Us?
        </h1>
        <p
          className="h-full flex flex-col text-[8px] leading-4 font-semibold text-[#333333] row-start-2 col-start-1 
  pl-6 pr-0 -mt-[60px] w-auto md:w-auto text-left font-Poppins md:ml-16 animate-slide-left"
        >
          Integrating technology, engineering expertise and rich industry
          experience, has helped Coptech Engineering in meeting the exact needs
          and requirements of the customers. We are profound manufacturer,
          supplier and exporter in the field of industrial mills. In our wide
          range, we have introduced Cold Rolling Mills, Tandem Type Rolling
          Mills, Hot Rolling Mills, Slitting Machines, Straightening Machines
          and others. All these products are identified with salient features
          like functional soundness, enhanced durability, scratch resistant,
          high performance, easy user interface, etc.
        </p>
        <div className="grid grid-cols-1 justify-center items-center mt-[70px] h-[250px] bg-none">
          <img
            src={ssvaidya}
            alt="SS vaidya"
            className="mx-auto drop-shadow-[0_15px_15px_rgba(0,0,0,0.5)] w-28 h-40 md:w-36 md:h-48 transition-transform 
      duration-200 transform hover:scale-95"
          />
          <p className="text-[16px] md:text-[20px] font-semibold text-[#333333] text-center -mt-6 md:-mt-2 font-AlexBrush">
            S.S. Vaidya
          </p>
        </div>
        <p
          className="text-[8px] leading-4 font-semibold text-[#333333] row-start-4 col-start-1 pl-6 pr-0 md:ml-16 -mt-[85px]
   h-auto md:-mt-[210px] w-[340px] lg:-mt-[250px] md:w-auto text-left font-Poppins custom-mt animate-slide-left"
        >
          Our products are quality tested and provide complete value for money.
          Besides, we ensure timely delivery of the products through our
          convenient supply channels. Owing to the reliable usability of our
          products and our strong business dynamics, we have carved a niche for
          ourselves in this competitive market. Also, we are one of the most
          preferred service providers of Turn Key/Consultancy for setting up
          complete plants for Copper Melting and casting plants, Non Ferrous
          Rolling Plants, Copper Extrusion plants, etc.
        </p>
      </div>
      <NavbarMobile />
    </div>
  );
};
export default AboutUs;
