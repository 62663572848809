import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import logo from "./logo.png";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
const MainDash = () => {
  const handleDeleteQuote = async (id) => {
    try {
      await fetch(`/api/deleteQuot?id=${id}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      setQuot(quotTab.filter((row) => row._id !== id));
    } catch (error) {
      console.error("Error deleting query:", error);
    }
  };

  const handleDeleteQuery = async (id) => {
    try {
      await fetch(`/api/deleteQuery?id=${id}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      setQuot(quotTab.filter((row) => row._id !== id));
    } catch (error) {
      console.error("Error deleting query:", error);
    }
  };

  const handleDeletePots = async (id) => {
    try {
      await fetch(`/api/deletePots?id=${id}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      setQuot(quotTab.filter((row) => row._id !== id));
    } catch (error) {
      console.error("Error deleting query:", error);
    }
  };
  const [quotTab, setQuot] = useState([]);
  const [queryTab, setQuery] = useState([]);
  const [potsTab, setPots] = useState([]);
  const fetchQuot = async () => {
    const quots = await fetch("/api/getQuot", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await quots.json();
    setQuot(data);
  };

  const fetchQuery = async () => {
    const query = await fetch("/api/getQuery", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await query.json();
    setQuery(data);
  };
  const fetchPots = async () => {
    const pots = await fetch("/api/getPots", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await pots.json();
    setPots(data);
  };
  useEffect(() => {
    fetchQuot();
    fetchQuery();
    fetchPots();
  }, [quotTab, queryTab, potsTab]);
  return (
    <div className="w-full h-full bg-[#a4a4a4] ">
      <div className="h-[8%] bg-[#00284d] flex justify-between items-center p-5">
        <div>
          <h2 className="text-white font-bold text-3xl ml-6 font-Poppins ">
            Welcome Back!
          </h2>
        </div>
        <div>
          <img src={logo} alt={"Coptech Logo"} className="h-16 w-36" />
        </div>
      </div>
      <div className="p-5 w-full  ">
        <div className="w-full pt-4 mb-10 rounded-md bg-[#00284d] bg-opacity-90 ">
          <h3 className="text-center text-[#FBEAEB] font-Poppins ">
            Quotation Table
          </h3>
          <div className="bg-white shadow-lg rounded-md my-4">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-[#EEEEEE] text-[#757575] uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  <th className="py-3 px-6 text-center">Company</th>
                  <th className="py-3 px-6 text-center">Machine</th>
                  <th className="py-3 px-6 text-center">Phone No.</th>
                  <th className="py-3 px-6 text-center"></th>
                </tr>
              </thead>
              <tbody className="text-[#757575] text-sm font-light">
                {quotTab.map((row) => (
                  <tr
                    key={row._id}
                    className="border-b border-[#EEEEEE] hover:bg-[#d2d2d2]"
                  >
                    <td className="py-3 px-6 text-left">{row.name}</td>
                    <td className="py-3 px-6 text-left">{row.email}</td>
                    <td className="py-3 px-6 text-center">{row.company}</td>
                    <td className="py-3 px-6 text-center">{row.machine}</td>
                    <td className="py-3 px-6 text-center">{row.phone}</td>
                    <td className="py-3 px-6 text-center">
                      {/* < XMarkIcon className=" h-5 w-5" /> */}
                      <button
                        onClick={() => handleDeleteQuote(row._id)}
                        className="bg-blue text-[#cc1919] font-Poppins font-medium hover:bg-[#cc1919]
                      hover:text-white py-1 px-5 rounded-md col-span-2 border border-[#cc1919]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full rounded-md pt-4 mb-10 bg-[#00284d] bg-opacity-90 ">
          <h3 className="text-center text-[#FBEAEB] font-Poppins ">
            Query Table
          </h3>
          <div className="bg-white shadow-lg rounded-md my-4">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-[#EEEEEE] text-[#757575] uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Query</th>
                  <th className="py-3 px-6 text-centre">Email</th>
                  <th className="py-3 px-6 text-center">Company</th>
                  <th className="py-3 px-6 text-center">City</th>
                  <th className="py-3 px-6 text-center">Phone</th>
                  <th className="py-3 px-6 text-center"></th>
                </tr>
              </thead>
              <tbody className="text-[#757575] text-sm font-light">
                {queryTab.map((row) => (
                  <tr
                    key={row._id}
                    className="border-b border-[#EEEEEE] hover:bg-[#d2d2d2]"
                  >
                    <td className="py-3 px-6 text-left">{row.fname}</td>
                    <td className="py-3 px-6 text-left">{row.content}</td>
                    <td className="py-3 px-6 text-center">{row.email}</td>
                    <td className="py-3 px-6 text-center">{row.company}</td>
                    <td className="py-3 px-6 text-center">{row.city}</td>
                    <td className="py-3 px-6 text-center">{row.phone}</td>
                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => handleDeleteQuery(row._id)}
                        className="bg-blue text-[#cc1919] font-Poppins font-medium hover:bg-[#cc1919] hover:text-white 
                     py-1 px-5 rounded-md col-span-2 border border-[#cc1919]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full rounded-md pt-4 bg-[#00284d] bg-opacity-90 ">
          <h3 className="text-center text-[#FBEAEB] font-Poppins ">
            Potential Customers
          </h3>
          <div className="bg-white shadow-lg rounded-md my-4">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-[#EEEEEE] text-[#757575] uppercase text-sm leading-normal">
                  <th className="py-3 px-24 text-left">Name</th>
                  <th className="py-3 px-6 text-center">Phone No.</th>
                  <th className="py-3 px-6 text-center"></th>
                </tr>
              </thead>
              <tbody className="text-[#757575] text-sm font-light">
                {potsTab.map((row) => (
                  <tr
                    key={row._id}
                    className="border-b border-[#EEEEEE] hover:bg-[#d2d2d2]"
                  >
                    <td className="py-3 px-6 text-left">{row.name}</td>
                    <td className="py-3 px-6 text-center">{row.phone}</td>
                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => handleDeletePots(row._id)}
                        className="bg-blue text-[#cc1919] font-Poppins font-medium hover:bg-[#cc1919] hover:text-white py-1 px-5 rounded-md col-span-2 border border-[#cc1919]"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDash;
