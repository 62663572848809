import React from "react";
import logo from "./logo.png";
const AddCar = () => {
  return (
    <div className="w-full h-full">
      <div className="h-[12%] bg-[#00284d] flex justify-between items-center p-5">
        <div>
          <h2 className="text-white font-bold text-xl font-Poppins mx-10 ">
            Job Applications Database
          </h2>
        </div>
        <div>
          <img src={logo} alt="Logo" className="h-16 w-36" />
        </div>
      </div>
      <div className="w-1/2 m-auto p-10 py-10 my-20 bg-[#00284d] bg-opacity-90 rounded-md" style={{ boxShadow: '0 10px 12px rgba(0, 0, 0, 0.3)' }} >
      <div>
          <h4 className="mx-1 font-semibold font-Poppins text-[#FBEAEB]">Applications received for various positions</h4>
        </div>
        <div className="flex justify-between my-5 mx-16 items-center">
          <h4 className="text-[#FBEAEB] text-xl font-normal font-Poppins  ">General Manager:</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/spreadsheets/d/1hIP7JY8snBThpYUQxYzYzsCKlMxiWM9hXDSwDlWccLI/edit?usp=sharing",
                "_blank"
              );
            }}
            className="bg-blue text-[#FBEAEB] font-Poppins font-medium hover:bg-[#1F2937] py-2 px-6 rounded-md col-span-2 border border-white"
          >
            Open
          </button>
        </div>
        <div className="flex justify-between my-5 mx-16 items-center">
          <h4 className="text-[#FBEAEB] text-xl font-normal font-Poppins  " >Machine Operator:</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/spreadsheets/d/1_tYjJ3ntt7VrqmcoJn6qEOkN2q_utO8VzZG0Ob6C7UM/edit?resourcekey#gid=1032129125",
                "_blank"
              );
            }}
            className="bg-blue text-[#FBEAEB] font-Poppins font-medium   hover:bg-[#1F2937] py-2 px-6 rounded-md col-span-2 border border-white"
          >
            Open
          </button>
        </div>
        <div className="flex justify-between my-5 mx-16 items-center">
          <h4 className="text-[#FBEAEB] font-Poppins text-xl font-normal  ">Mechanical Engineer:</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/spreadsheets/d/17aWe3uVzbUkEXvgA5-HzkDWevBQGYXSUpXEdYcvdBjc/edit?resourcekey&usp=forms_web_b#gid=1197364311",
                "_blank"
              );
            }}
            className="bg-blue text-[#FBEAEB] font-Poppins hover:bg-[#1F2937] py-2 px-6 rounded-md col-span-2 border border-white"
          >
            Open
          </button>
        </div>
        <div className="flex justify-between my-5 mx-16 items-center">
          <h4 className="text-[#FBEAEB] font-Poppins text-xl font-normal  " >AutoCAD Designer:</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/spreadsheets/d/1woWvmYgSH9fuo9-atlfWBU9OySRnu_6ZxULA_6kOz7I/edit?resourcekey#gid=1940307510",
                "_blank"
              );
            }}
            className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] py-2 px-6 rounded-md col-span-2 border border-white"
          >
            Open
          </button>
        </div>
        <div className="flex justify-between my-5 mx-16 items-center">
          <h4 className="text-[#FBEAEB] font-Poppins text-xl font-normal" >Painter:</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/spreadsheets/d/1ZzrqBPx1YoNfDeZ_odRCjS8dUc3lgIrNPJgxgHjes3k/edit?resourcekey#gid=1090966988",
                "_blank"
              );
            }}
            className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] py-2 px-6 rounded-md  col-span-2 border border-white"
          >
            Open
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCar;
