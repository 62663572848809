import React, { useState, useEffect } from "react";
import img from "./Assets/LT.png";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const CompanySlideshow = () => {
  const [companies, setCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);

  // Sample company logo data
  const companyData = [
    { logo: "/Assets/LT.png", alt: "Company 1" },
    { logo: "/Assets/bedmutha.png", alt: "Company 2" },
    { logo: "/Assets/rrgobal_logo.png", alt: "Company 3" },
    { logo: "/Assets/genus.png", alt: "Company 4" },
    { logo: "/Assets/coprrod.png", alt: "Company 5" },
    { logo: "/Assets/tamra.png", alt: "Company 6" },
    { logo: "/Assets/E&U_Logo.svg", alt: "Company 7" },
    { logo: "/Assets/nuhas.png", alt: "Company 8" },
    { logo: "/Assets/pprolling.png", alt: "Company 9" },
    { logo: "/Assets/jai-bhawani.png", alt: "Company 10" },
    { logo: "/Assets/apar.png", alt: "Company 11" },
    { logo: "/Assets/bhagyanagar-logo.jpg", alt: "Company 12" },
    { logo: "/Assets/emjay.png", alt: "Company 13" },
    { logo: "/Assets/pahwa.webp", alt: "Company 14" },
    { logo: "/Assets/MEVA-Flag.gif", alt: "Company 15" },
    // { logo: '/Assets/tradeindia.svg', alt: 'Company 15', size: '20x20' },
    { logo: "/Assets/xicon.png", alt: "Company 16" },
    // Add more company logos as needed
  ];

  useEffect(() => {
    // Simulating an API call to fetch company data
    setTimeout(() => {
      setCompanies(companyData);
      setVisibleCompanies(companyData.slice(0, 4));
    }, 1000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVisibleIndex((prevIndex) => (prevIndex + 4) % companies.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [companies]);

  useEffect(() => {
    setVisibleCompanies(
      companyData.slice(currentVisibleIndex, currentVisibleIndex + 4)
    );
  }, [currentVisibleIndex, companyData]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className="flex flex-col items-center bg-slate p-8">
      <Link to="/ourclients">
        <h2 className="text-3xl font-bold mb-2 mt-6 text-blackrgba font-serif">
          Our Customers
        </h2>
      </Link>

      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 md:gap-16 lg:gap-24">
        {visibleCompanies.map((company, index) => (
          <div
            key={index}
            className={`flex items-center transition-opacity duration-1000 `}
          >
            {/* ${isDesktopOrLaptop ? 'animate-bounce' : 'animate-pulse'} */}
            <img
              src={company.logo}
              alt={company.alt}
              className="h-44 w-44 object-contain opacity-100"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanySlideshow;
