import React, { useState } from "react";
import logo from "./logo.png";

const AddImg = () => {
  const [name, setName] = useState("");
  const [image1, setImage1] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("image1", image1);

    try {
      const response = await fetch("/api/addImage", {
        method: "POST",

        body: formData,
      });

      if (response.ok) {
        console.log("Image added successfully");
        // Reset the form
        setName("");
        setImage1(null);
      } else {
        console.log("Failed to add image");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full ">
      <div className="h-[16%] bg-[#00284d] flex justify-between items-center p-3">
        <div>
          <h2 className="text-white font-bold text-2xl mx-8 font-Poppins">
            Add Image
          </h2>
        </div>
        <div>
          <img src={logo} alt="Logo" className="mx-8 h-16 w-36" />
        </div>
      </div>
      <div className="overflow-x-hidden ">
        <form
          onSubmit={handleSubmit}
          className="w-[100%] h-[90vh] flex justify-center items-center"
        >
          <div
            className="bg-[#00284d] bg-opacity-90 rounded-md px-5 py-8 border -mt-16 "
            style={{ boxShadow: "0 10px 12px rgba(0, 0, 0, 0.3)" }}
          >
            {/* <h2 className="text-center text-[#FBEAEB] font-Poppins">Add Image</h2>
        <br />
        <br /> */}
            <div>
              <h4 className="mx-16 font-semibold font-Poppins text-[#FBEAEB]">
                Add a new image
              </h4>
            </div>
            <div className="px-5 mx-10 py-5">
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] font-Poppins mb-2"
                  htmlFor="category"
                >
                  Caption:
                </label>
                <input
                  className="p-2 rounded-md bg-[#F9FAFB] border border-[#D1D5DB] text-[#111827] text-sm focus:ring-[#3B82F6] focus:border-[#3B82F6] 
            block w-full dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] dark:text-[#F9FAFB] dark:focus:ring-[#3B82F6] dark:focus:border-[#3B82F6]"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="px-5 mx-10 py-5 -mt-2">
              <div className="flex flex-col">
                <label
                  className="text-[#FBEAEB] font-Poppins mb-2"
                  htmlFor="desc"
                >
                  Upload Image:
                </label>
                <input
                  className="p-2 rounded-md block w-full text-sm text-[#111827] border border-[#D1D5DB] 
            cursor-pointer bg-[#F9FAFB] dark:text-[#9CA3AF] focus:outline-none dark:bg-[#1F2937] dark:border-[#4B5563] dark:placeholder-[#9CA3AF] file:bg-[#4B5563] 
            file:border-0 file:overflow-hidden  file:border-solid file:border-inherit file:-mx-3 file:-my-[0.50rem] file:px-3 file:py-2 file:text-[#c0c0c0] hover:file:text-[#111827]
            file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-[#b1b1b1]  "
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="px-5 mx-10 py-5 ">
              <div className="flex flex-col">
                <button
                  type="submit"
                  className="bg-blue text-[#FBEAEB] hover:bg-[#1F2937] p-2 rounded-md col-span-2 border border-white relative group overflow-hidden"
                  onClick={(e) => handleSubmit(e)}
                >
                  {/* <span className="absolute w-1 h-1 transition-all duration-300 origin-center -translate-x-1/2 -translate-y-1/2 
  bg-[#1F2937] top-1/2 left-1/2 group-hover:w-64 group-hover:h-64 group-hover:-translate-x-32 group-hover:-translate-y-32 ease"></span>
  <span className="relative z-10 text-white transition duration-300 group-hover:text-white ease">Submit</span> */}

                  <span class="absolute w-0 h-0 transition-all duration-300 origin-center rotate-60 -translate-x-20 bg-indigo-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                  <span class="relative text-indigo-600 transition duration-300 group-hover:text-white ease">
                    Submit
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddImg;
