import { createContext, useContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const login = async ({ email, password }) => {
    const res = await axios.post("/api/Login", {
      email,
      password,
    });

    setCookies("token", res.data.token); // your token
    setCookies("email", res.data.email); // optional data

    navigate("/dash");
    return res;
  };

  const logout = () => {
    ["token", "email"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    navigate("/");
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
