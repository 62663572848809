import React from "react";
import Navbar from "../Navbar/Navbar";
import NavbarMobile from "../Navbar/NavbarMobile";
import Careers from "../Career/Careers";
import CareerMob from "../Career/CareesMob";
import { useState, useEffect } from "react";
import Gallery from "../Gallery/Gallery";
import SinglePr from "../ProductCarousel/SinglePr";
import GalleryMob from "../Gallery/GalleryMob";
import MainProduct from "../ProductCarousel/MainProduct";
import ProductMob from "../ProductCarousel/ProductMob";
import Footer2 from "../Footerm/Footer2";
import FooterMob from "../Footerm/FooterMob";
import AboutPage from "../AboutUs/AboutUsPage";
import ContactComponent from "../query/form_main";
import { ImageOutlined } from "@mui/icons-material";
import Integrate from "../VideoSection/Integrate";
import { useMediaQuery } from "react-responsive";
import CompanyDetailR from "../detail/detailResponsive";
import Slideshow1 from "../slideshow/Slideshow1";
import CompanySlideshow from "../Our Clients/Clients";
import AddPrd from "../Admin/AddPrd";
import Dashboard from "../Admin/Dashboard";
import MobileTop from "../Navbar/MobileTop";
import { Link, Element } from "react-scroll";
import Pcard from "../ProductCarousel/Pcard";
const AppMain = () => {
  const [ptn, setptn] = useState({
    name: "",
    phone: "",
  });
  localStorage.setItem("prdid", "");
  const handleSubmit = async (e) => {
    e.preventDefault();

    const rep = await fetch("/api/savePots", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(ptn),
    });
    setShowDialog(false);
  };
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDialog(true);
    }, 60000);

    return () => clearTimeout(timer);
  }, []);
  const handleCloseDialog = () => {
    setShowDialog(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setptn((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <div className="overflow-x-hidden">
      {showDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-10 backdrop-filter backdrop-blur-sm">
          <div className="bg-white rounded-lg p-8 shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">
              Get a Call for more info
            </h3>
            <p className="text-gray-600 mb-4 ">
              Enter your name and phone number, our people will connect with
              you.
            </p>
            <input
              className="p-2 outline-none m-2 bg-[#D3D3D3] placeholder:text-blackrgba"
              type="text"
              placeholder="Name"
              name="name"
              value={ptn.name}
              onChange={(e) => handleChange(e)}
            />
            <input
              className="p-2 outline-none m-2 bg-[#D3D3D3] placeholder:text-blackrgba"
              type="text"
              placeholder="Phone"
              name="phone"
              value={ptn.phone}
              onChange={(e) => handleChange(e)}
            />
            <div className="flex justify-center gap-10 mt-3">
              <button
                onClick={handleSubmit}
                className="px-4  py-2  text-white font-semibold   rounded "
                style={{ backgroundColor: "#003A72" }}
              >
                Submit
              </button>
              <button
                onClick={handleCloseDialog}
                className="px-4 py-2  text-white font-semibold  rounded "
                style={{ backgroundColor: "#cf222e" }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isDesktopOrLaptop && (
        <>
          <Navbar />
          <Slideshow1 />
          <Integrate />
          <CompanySlideshow />
          <MainProduct />
          <ContactComponent />
          <CompanyDetailR />
          {/* <AddPrd /> */}

          <div>
            <Element name="footer" />
          </div>
          <Footer2 />
        </>
      )}
      <div className="overflow-y-scroll overflow-x-hidden">
        {isTabletOrMobile && (
          <>
            <MobileTop />
            <Slideshow1 />
            <Integrate />
            <CompanySlideshow />
            <div>
              <ProductMob />
            </div>
            <ContactComponent />
            <CompanyDetailR />
            <div>
              {" "}
              <Element name="footer" />
            </div>
            <FooterMob />

            <NavbarMobile />
          </>
        )}
      </div>
    </div>
  );
};

export default AppMain;
