import React, { useEffect, useState, useMemo } from "react";
// import M2 from "/img1.webp";
// import M3 from "/img1.webp";
// import M4 from "/img1.webp";

import { Link } from "react-router-dom";
const Slideshow1 = () => {
  const images = useMemo(
    () => [
      {
        src: "/img4.webp",
        alt: "Coptech Engineering: Powering Metal Transformation",
      },
      {
        src: "/img1.webp",
        alt: "Innovation in Copper Rolling: Coptech Engineering at the Forefront",
      },
      {
        src: "/img2.webp",
        alt: "Empowering Industries with Advanced Metal Processing: Coptech Engineering",
      },
    ],
    []
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, images]);

  const { src, alt } = images[currentImageIndex];

  const divStyle = {
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Increase the height as per your requirement
  };

  return (
    <div>
      <div className="bg-cover bg-center relative" style={divStyle}>
        <div class="flex justify-start ml-10 items-end h-96">
          <h1 class="text-white text-2xl lg:text-4xl font-bold w-750 ">
            {alt}
          </h1>
        </div>
        <button className=" bottom-10 ml-10 mt-20 bg-yelloi hover:bg-yellof hover:bg-yellow-400 focus:bg-yellow-400 focus:outline-none text-white font-bold py-2 px-4 rounded">
          <Link
            className="text-white hover:no-underline hover:text-white md:hidden"
            to="/aboutm"
          >
            Know More
          </Link>
          <Link
            className="text-white hover:no-underline hover:text-white hidden md:block"
            to="/aboutus"
          >
            Know More
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Slideshow1;
